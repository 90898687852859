import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import InformalOffer from 'domain/model/informal_offer';
import { Sortable, Filterable } from 'domain/repository/params';
import { Count } from 'domain/repository/res';
import Base from 'domain/repository/base';

export default class InformalOfferRepository extends Base {
  async fetchIndex({
    page,
    sort,
    filters,
  }: {
    page?: number;
    sort?: Sortable | null;
    filters?: Filterable[];

  }) {
    return this.api.get(endpoint.common.informalOffer.index(), {params: {page, ...sort, filters: filters}}).then(res => {
      const response: { informalOffers: InformalOffer[]; total: number } = {
        informalOffers: res.data.informal_offers.map((offer: InformalOffer) =>
          plainToClass(InformalOffer, offer, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };

  async fetchCount() {
    return this.api.get(endpoint.common.informalOffer.total()).then(res => {
      return {
        total: res.data.total,
      } as Count;
    });
  };
}
