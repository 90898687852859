import React, { useContext, createContext, useEffect } from "react";
import { useHistory } from 'react-router-dom';

import AdminStaff from 'domain/model/admin_staff';
import { removeToken } from 'utils/storage';
import routes from 'utils/routes';
import { useService } from 'components/lib/Service';
import { AuthService } from 'domain/service';

type AuthContextProps = {
  staff: AdminStaff | null;
  // token: string,
  logout: () => void;
  setStaff: (staff: AdminStaff) => void;
}

const AuthContext = createContext<AuthContextProps>({staff: null, logout: () => {}, setStaff: () => {}});

export const useAuth = () => useContext(AuthContext)

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const authService = useService(AuthService)

  const [staff, setStaff] = React.useState<AdminStaff | null>(null)

  useEffect(() => {
    authService.fetchMe().then(res => {
      setStaff(res);
    }).catch(err => {
      history.push(routes.common.login.path);
    })
  }, [])

  const logout = () => {
    removeToken();
    history.push(routes.common.login.path);
  }

  return (
    <AuthContext.Provider
      value={{
        staff,
        logout,
        setStaff,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

