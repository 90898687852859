import React, { useState, useEffect } from "react";
import {
  Toolbar,
  Typography,
  Grid,
  CardContent,
} from '@material-ui/core';
import { RowsProp, DataGrid, RowParams } from '@material-ui/data-grid';

import { MessageRepository } from 'domain/repository';
import Message from 'domain/model/message';
import { MessageFieldDefinition } from 'domain/model/field_difinition';
import { useRepository } from 'components/lib/Repository';
import { CustomPagination } from 'components/Pagination';
import { useFilter } from "hooks/useFilter";
import { FilterForm } from 'components/Filter';
import { CustomModal } from 'components/Modal';
import { useIndex } from 'hooks/useIndex';

export const OfficialMessageIndex = () => {
  const messageRepository = useRepository(MessageRepository);
  const [rows, setRows] = useState<RowsProp>([]);
  const {
    page,
    totalPage,
    sort,
    sortingMode,
    handlePageChange,
    handleSortChange,
    changeTotal,
  } = useIndex();
  const { filters, handleSetFilters, } = useFilter();
  const [opened, setOpened] = useState<boolean>(false);
  const [row, setRow] = useState<Message | null>(null);

  const handleClose = () =>   {
    setOpened(false);
  }

  useEffect(() => {
    messageRepository.fetchIndex({page, sort, filters: filters, official: true}).then(res => {
      const { total } = res;
      changeTotal(total);
      setRows(res.messages);
    });
  }, [page, sort, filters]);

  const handleClickRow = (params: RowParams) => {
    setRow(params.row as Message)
    setOpened(true)
  }

   return (
    <>
      <Toolbar>公式メッセージ一覧</Toolbar>
      <FilterForm
        handleFilter={handleSetFilters}
        fieldDefinition={MessageFieldDefinition.filter(v => v.field != 'company_id')}
      />
      <div style={{ width: '100%', minHeight: 600 }}>
        <DataGrid
          rows={rows}
          columns={[
            {
              field: 'id',
              width: 100,
            },
            {
              field: 'userId',
              headerName:"ユーザーID",
              width: 100,
              sortable: false,
            },
            {
              field: 'userName',
              headerName:"ユーザー名",
              width: 250,
              sortable: false,
            },
            {
              field: 'createdAt',
              headerName:"登録日",
              sortable: false,
            },
            {
              field: 'updatedAt',
              width: 200,
              headerName:"更新日",
              sortable: false,
            },
          ]}
          pagination
          rowCount={totalPage}
          paginationMode="server"
          sortingMode={sortingMode}
          onPageChange={handlePageChange}
          onSortModelChange={handleSortChange}
          components={{pagination: CustomPagination}}
          onRowClick={handleClickRow}
          rowsPerPageOptions={[100]}
          disableColumnResize={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          loading={false}
        />
      </div>
      <CustomModal
        opened={opened}
        handleClose={handleClose}
      >
        <CardContent>
        <Grid container spacing={3} style={{padding: '20px'}}>
          <Grid item xs={2}>
            <Typography>content</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography style={{ overflowWrap: 'break-word', maxWidth: '750px' }}>{row?.content}</Typography>
          </Grid>
        </Grid>
        </CardContent>
      </CustomModal>
    </>
  );
};
