import React, { useState } from 'react';
import {
  Button,
  Paper,
  Grid,
  Toolbar,
  Typography,
  Card,
  CardContent,
  TextField,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ArrowDropDownCircle } from "@material-ui/icons";

import { ArchiveService } from 'domain/service';
import { useLoading } from 'components/lib/LoadingProvider';
import { useService } from 'components/lib/Service';
import { Filterable } from 'domain/repository/params';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    margin: {
      margin: theme.spacing(1),
    },
  }),
)

const companyTables = [
  { table: "companies", displayName: "採用担当者情報"},
  { table: "company_assessments", displayName: "企業アセスメント"},
  { table: "company_details", displayName: "会社紹介"},
  { table: "company_favorites", displayName: "お気に入り人材"},
  { table: "company_introductions", displayName: "紹介者情報"},
  { table: "company_profiles", displayName: "企業情報"},
  { table: "company_recruits", displayName: "募集要項"},
  { table: "employee_assessments", displayName: "従業員アセスメント"},
  { table: "employee_voices", displayName: "先輩社員の声"},
];

const commonTables = [
  { table: "informal_offers", displayName: "オファー"},
  { table: "matching_scores", displayName: "マッチスコア"},
  { table: "messages", displayName: "メッセージ"},
];

const userTables = [
  { table: "users", displayName: "ユーザー情報"},
  { table: "user_academic_histories", displayName: "学歴"},
  { table: "user_assessments", displayName: "アセスメント結果"},
  { table: "user_career_histories", displayName: "職歴"},
  { table: "user_favorites", displayName: "お気に入り企業"},
  { table: "user_jobs", displayName: "希望職種"},
  { table: "user_prefectures", displayName: "希望エリア"},
  { table: "user_qualifications", displayName: "免許・資格"},
  { table: "user_secrets", displayName: "開示したくない企業"},
];

const TableGroup = [
  {
    title: "企業関連",
    tables: companyTables,
  },
  {
    title: "求職者関連",
    tables: userTables,
  },
  {
    title: "その他",
    tables: commonTables,
  },
]

export const CsvIndex = () =>{
  const classes = useStyles();
  const { startLoading, endLoading } = useLoading();
  const archiveService = useService(ArchiveService)
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");

  const handleFromDateChange = (date: string) => {
    setFromDate(date);
  };

  const handleToDateChange = (date: string) => {
    setToDate(date);
  };

  const handleDownload = async (tableName: string) => {
    const filters: Filterable[] = [];
    fromDate && (filters.push({
      field: "created_at",
      operator: "GTE",
      value: fromDate,
    }))
    toDate && (filters.push({
      field: "created_at",
      operator: "LTE",
      value: toDate,
    }))

    try {
      startLoading();
      await archiveService.downloadCsv({tableName, filters});
    } finally {
      endLoading();
    }
  }

  return (
    <>
      <Toolbar>CSVダウンロード</Toolbar>
      <Card style={{marginBottom: 20}}>
        <CardContent>
          <Typography>日付で絞り込み</Typography>
          <Typography color="secondary" variant="body2">※一度にダウンロード可能な件数は最大3000件です</Typography>
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item md={5}>
              <DateField
                handleChange={handleFromDateChange}
                date={fromDate}
                label="from"
              />
            </Grid>
            <Grid item md={1}>
              <Typography style={{textAlign: "center"}}>〜</Typography>
            </Grid>
            <Grid item md={5}>
              <DateField
                handleChange={handleToDateChange}
                date={toDate}
                label="to"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {TableGroup.map((v) => (
        <div key={v.title}>
          <Typography className={classes.margin} variant="h6">{v.title}</Typography>
          <Grid container spacing={2}>
            {v.tables.map((tableName) =>
              <Grid key={tableName.table} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={classes.paper}>
                  <p>{tableName.displayName}</p>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.margin}
                    startIcon={<ArrowDropDownCircle />}
                    onClick={() => handleDownload(tableName.table)}
                  >
                    download
                  </Button>
                </Paper>
              </Grid>
            )}
          </Grid>
        </div>
      )
      )}
   </>
  );
}

const dateStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

type DateFieldProps = {
  handleChange: (date: string) => void,
  date: string,
  label: "from" | "to",
}

const DateField: React.FC<DateFieldProps> = ({
  handleChange,
  date,
  label,
}) => {
  const classes = dateStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        label={label}
        type="datetime-local"
        value={date}
        onChange={e => handleChange(e.target.value)}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
  );
}
