import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import DirectlyAssessment from 'domain/model/directly_assessment';
import { Sortable, Filterable } from 'domain/repository/params';
import Base from 'domain/repository/base';

export default class DirectlyAssessmentRepository extends Base {
  async fetchIndex({
    page,
    sort,
    filters,
  }: {
    page?: number;
    sort?: Sortable | null;
    filters?: Filterable[];
  }) {
    return this.api.get(endpoint.assessment.directly.index(), {params: {page, ...sort, filters: filters}}).then(res => {
      const response: { assessments: DirectlyAssessment[]; total: number } = {
        assessments: res.data.assessments.map((assessment: DirectlyAssessment) =>
          plainToClass(DirectlyAssessment, assessment, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };

  async fetchShow({
    id,
  }: {
    id: string;
  }) {
    return this.api.get(endpoint.assessment.directly.show(id)).then(res => {
      return plainToClass(DirectlyAssessment, res.data.assessment, { excludeExtraneousValues: true })
    });
  };
}
