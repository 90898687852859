import React, { useEffect, useState, useCallback } from "react";
import {
  Toolbar,
  Grid,
} from '@material-ui/core';
import { useParams, useHistory, generatePath } from "react-router-dom";

import { UserAcademicHistoryIndex } from 'pages/UserFactsheet/Show/UserAcademicHistoryIndex';
import { UserJobIndex } from 'pages/UserFactsheet/Show/UserJobIndex';
import { UserPrefectureIndex } from 'pages/UserFactsheet/Show/UserPrefectureIndex';
import { UserSecretIndex } from 'pages/UserFactsheet/Show/UserSecretIndex';
import { UserQualificationIndex } from 'pages/UserFactsheet/Show/UserQualificationIndex';
import { UserCareerHistoryIndex } from 'pages/UserFactsheet/Show/UserCareerHistoryIndex';

type IdParams = {
  id: string;
};

export const UserFactsheetShow = () => {
  const { id } = useParams<IdParams>();

  return (
    <>
      <Toolbar>ファクトシート</Toolbar>
      <Grid container spacing={1}>
        <Grid item sm={12} lg={6}>
          <UserAcademicHistoryIndex userId={id}/>
        </Grid>
        <Grid item sm={12} lg={6}>
          <UserCareerHistoryIndex userId={id}/>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={12} lg={6}>
          <UserJobIndex userId={id}/>
        </Grid>
        <Grid item sm={12} lg={6}>
          <UserQualificationIndex userId={id}/>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={12} lg={6}>
          <UserPrefectureIndex userId={id}/>
        </Grid>
        <Grid item sm={12} lg={6}>
          <UserSecretIndex userId={id}/>
        </Grid>
      </Grid>
    </>
  );
};
