import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import UserAcademicHistory from 'domain/model/user_academic_history';
import Base from 'domain/repository/base';

export default class UserAcademicHistoryRepository extends Base {
  async fetchIndex({
    userId,
    page,
  }: {
    userId: string;
    page?: number;
  }) {
    return this.api.get(endpoint.user.academicHistory.index(userId), {params: {page}}).then(res => {
      const response: { academicHistories: UserAcademicHistory[]; total: number } = {
        academicHistories: res.data.academic_histories.map((h: UserAcademicHistory) =>
          plainToClass(UserAcademicHistory, h, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };
}
