import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import UserAssessment from 'domain/model/user_assessment';
import { Sortable, Filterable } from 'domain/repository/params';
import { Count } from 'domain/repository/res';
import Base from 'domain/repository/base';

export default class UserAssessmentRepository extends Base {
  async fetchIndex({
    page,
    sort,
    filters,
  }: {
    page?: number;
    sort?: Sortable | null;
    filters?: Filterable[];
  }) {
    return this.api.get(endpoint.assessment.user.index(), {params: {page, ...sort, filters: filters}}).then(res => {
      const response: { assessments: UserAssessment[]; total: number } = {
        assessments: res.data.assessments.map((assessment: UserAssessment) =>
          plainToClass(UserAssessment, assessment, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };

  async fetchShow({
    id,
  }: {
    id: string;
  }) {
    return this.api.get(endpoint.assessment.user.show(id)).then(res => {
      return plainToClass(UserAssessment, res.data.assessment, { excludeExtraneousValues: true })
    });
  };

  async fetchCount() {
    return this.api.get(endpoint.user.userAssessment.total()).then(res => {
      return {
        total: res.data.total,
        totalToday: res.data.total_today,
      } as Count;
    });
  };

}
