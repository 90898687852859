import React, { useState } from "react";
import { ComponentProps } from '@material-ui/data-grid';
import {
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  ArrowDropDown,
} from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    footerSpan: {
      marginLeft: 'auto',
      padding: 12,
      fontSize: 14,
      display: 'inline-block',
    },
    footer: {
      marginLeft: 'auto',
      float: "right",
    },
  }),
);

export const CustomPagination: React.FC<ComponentProps> = (props) => {
  const classes = useStyles();
  const {
    pagination,
    api,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRowClick = (e: any, page: number) => {
    setAnchorEl(null);
    api.current.setPage(page);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const list = [];
  for(var i = 1; i<=pagination.pageCount; i++) {
    list.push(i);
  }

  return (
    <td className={classes.footer}>
      <span className={classes.footerSpan}>Total: {props.pagination.rowCount}</span>
      <span className={classes.footerSpan}>
        Page:
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          {props.pagination.page}
          <ArrowDropDown />
        </Button>
      </span>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 250,
          },
        }}
      >
        {
          list.map((v, i) => (
            <MenuItem key={i} onClick={(e) => handleRowClick(e, v)}>{v}</MenuItem>
          ))
        }
      </Menu>
    </td>
  )
}
