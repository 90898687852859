import React, { useEffect, useState } from "react";
import { RowsProp, DataGrid } from '@material-ui/data-grid';
import {
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { useIndex } from 'hooks/useIndex';
import { useRepository } from 'components/lib/Repository';
import { CustomPagination } from 'components/Pagination';
import { UserSecretRepository } from 'domain/repository';

type Props = {
  userId: string,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginBottom: theme.spacing(5),
    },
  })
);

export const UserSecretIndex: React.FC<Props> = ({userId}) => {
  const classes = useStyles();
  const repo = useRepository(UserSecretRepository);
  const [rows, setRows] = useState<RowsProp>([]);
  const {
    page,
    totalPage,
    handlePageChange,
    changeTotal,
  } = useIndex();

  useEffect(() => {
    repo.fetchIndex({userId, page}).then(res => {
      const { total } = res;
      changeTotal(total);
      setRows(res.secrets);
    })
  }, [userId])

  return (
    <Card className={classes.margin}>
      <CardHeader title="非公開企業一覧" />
      <CardContent style={{ width: '100%', minHeight: 600 }}>
        <DataGrid
          rows={rows}
          columns={[
            {
              field: 'id',
              sortable: false,
            },
            {
              field: 'companyName',
              headerName: '企業名',
              width: 200,
              sortable: false,
            },
            {
              field: 'createdAt',
              headerName: '登録日',
              width: 200,
              sortable: false,
            },
            {
              field: 'updatedAt',
              headerName: '更新日',
              width: 200,
              sortable: false,
            },
          ]}
          pagination
          rowCount={totalPage}
          paginationMode="server"
          onPageChange={handlePageChange}
          components={{pagination: CustomPagination}}
          rowsPerPageOptions={[100]}
          disableColumnResize={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          loading={false}
        />
      </CardContent>
    </Card>
  );
}
