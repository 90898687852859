import React from "react";
import {
  CircularProgress,
  Backdrop,
} from '@material-ui/core';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import { useLoading } from 'components/lib/LoadingProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.tooltip + 1,
      color: '#fff',
    },
  }),
);

export const Loading: React.FC = () => {
  const { state } = useLoading();
  const classes = useStyles();

  return (
    <Backdrop
      className={ classes.backdrop }
      open={state.count > 0}
    >
      <CircularProgress />
    </Backdrop>
  );
};
