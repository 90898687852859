import React, { useState, useEffect } from "react";
import { RowsProp, DataGrid } from '@material-ui/data-grid';
import { Toolbar } from '@material-ui/core';

import { UserInformalOfferRepository } from 'domain/repository';
import { useRepository } from 'components/lib/Repository';
import { CustomPagination } from 'components/Pagination';
import { useFilter } from "hooks/useFilter";
import { FilterForm } from 'components/Filter';
import { InformalOfferFieldDefinition } from 'domain/model/field_difinition';
import { useIndex } from 'hooks/useIndex';

export const UserInformalOfferIndex = () => {
  const userInformalOfferRepository = useRepository(UserInformalOfferRepository);
  const [rows, setRows] = useState<RowsProp>([]);
  const {
    page,
    totalPage,
    sort,
    sortingMode,
    handlePageChange,
    handleSortChange,
    changeTotal,
  } = useIndex();
  const { filters, handleSetFilters, } = useFilter();

  useEffect(() => {
    userInformalOfferRepository.fetchIndex({ page, sort, filters: filters }).then(res => {
      const { total } = res;
      changeTotal(total);
      setRows(res.userInformalOffers);
    });
  }, [page, sort, filters]);

  return (
    <>
      <Toolbar>内定報告一覧</Toolbar>
      <FilterForm
        handleFilter={handleSetFilters}
        fieldDefinition={InformalOfferFieldDefinition}
      />
      <div style={{ width: '100%', minHeight: 600 }}>
        <DataGrid
          rows={rows}
          columns={[
            {
              field: 'id',
            },
            {
              field: 'userId',
              headerName: "ユーザーID",
              width: 150,
              sortable: false,
            },
            {
              field: 'userName',
              headerName: "ユーザー名",
              width: 300,
            },
            {
              field: 'companyName',
              headerName: "企業名",
              width: 250,
              sortable: false,
            },
            {
              field: 'offerType',
              headerName: "オファータイプ",
              width: 200,
              sortable: false,
            },
            {
              field: 'createdAt',
              headerName: "登録日",
              sortable: false,
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: "更新日",
              sortable: false,
              width: 200,
            },
          ]}
          pagination
          rowCount={totalPage}
          paginationMode="server"
          sortingMode={sortingMode}
          onPageChange={handlePageChange}
          onSortModelChange={handleSortChange}
          components={{ pagination: CustomPagination }}
          rowsPerPageOptions={[100]}
          disableColumnResize={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          loading={false}
        />
      </div>
    </>
  );
}
