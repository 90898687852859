import endpoint from 'utils/endpoint';
import { Filterable } from 'domain/repository/params';
import Base from 'domain/repository/base';

export default class ArchiveService extends Base {
  async downloadCsv({
    tableName,
    filters,
  } : {
    tableName: string,
    filters?: Filterable[],
  }) {
    return this.api.get(
      endpoint.common.archive.index(),
      {
        params: {table_name: tableName, filters: filters},
        responseType: "blob",
      },
    ).then(res => {
      const blob = res.data
      const fileURL = window.URL.createObjectURL(blob);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', tableName + ".csv");
      document.body.appendChild(fileLink);
      fileLink.click();

      return res;
    });
  }
}
