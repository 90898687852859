import React, { useContext, createContext, useReducer } from "react";

type LoadingState = {
  count: number,
}

type LoadingContextProps = {
  state: LoadingState
  startLoading: () => void
  endLoading: () => void
}

type loadingAction = 'START' | 'END'

const loadingReducer = (state: LoadingState, action: loadingAction): LoadingState => {
  switch (action) {
    case 'START':
      return { count: state.count + 1 };
    case 'END':
      return { count: state.count - 1 };
    default:
      return state;
  };
};

const initialState: LoadingState = {
  count: 0,
}

const LoadingContext = createContext<LoadingContextProps>({
  state: initialState,
  startLoading: () => {},
  endLoading: () => {},
});

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider: React.FC = ({ children }) => {
  const loading = useLoading()
  const [state, dispatch] = useReducer(loadingReducer, loading.state);

  return (
    <LoadingContext.Provider
      value={{
        state,
        startLoading: () => dispatch('START'),
        endLoading: () => dispatch('END'),
      }}
    >
      {children}
    </LoadingContext.Provider>
  )
};
