import React from "react";
import {
  Modal,
  Card,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalBody: {
      width: '60%',
      position: 'absolute',
      margin: 'auto',
      left:  0,
      right: 0,
      top: '30%',
    },
  }),
);

export const CustomModal: React.FC<{
  opened: boolean,
  handleClose: () => void,
  children?: React.ReactNode,
}> = ({
  opened,
  handleClose,
  children,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={opened}
      onClose={handleClose}
    >
      <Card variant="outlined" className={classes.modalBody}>
        {children}
      </Card>
    </Modal>
  )
}
