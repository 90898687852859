import { AxiosInstance } from "axios";

export default class Base {
  api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api;
  }
}

