import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import EmployeeAssessment from 'domain/model/employee_assessment';
import { Sortable, Filterable } from 'domain/repository/params';
import Base from 'domain/repository/base';

export default class EmployeeAssessmentRepository extends Base {
  async fetchIndex({
    page,
    sort,
    filters,
  }: {
    page?: number;
    sort?: Sortable | null;
    filters?: Filterable[];

  }) {
    return this.api.get(endpoint.assessment.employee.index(), {params: {page, ...sort, filters: filters}}).then(res => {
      const response: { assessments: EmployeeAssessment[]; total: number } = {
        assessments: res.data.assessments.map((assessment: EmployeeAssessment) =>
          plainToClass(EmployeeAssessment, assessment, {
            excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };

  async fetchShow({
    id,
  }: {
    id: string;
  }) {
    return this.api.get(endpoint.assessment.employee.show(id)).then(res => {
      return plainToClass(EmployeeAssessment, res.data.assessment, { excludeExtraneousValues: true })
    });
  };
}
