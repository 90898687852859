import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import CompanyWorkplace from 'domain/model/company_workplace';
import Base from 'domain/repository/base';

export default class CompanyWorkplaceRepository extends Base {
  async fetchIndex({
    companyId,
  }: {
    companyId: string;
  }) {
    return this.api.get(endpoint.company.workplaces.index(companyId)).then(res => {
      const response: { places: CompanyWorkplace[]; total: number } = {
        places: res.data.workplaces.map((workplace: CompanyWorkplace) =>
          plainToClass(CompanyWorkplace, workplace, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      }
      return response;
    });
  };
}
