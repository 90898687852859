import { Expose, Transform } from "class-transformer";
import dayjs from "dayjs";


export class UserBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "assessment_id"})
  assessmentId: number;

  @Expose({name: "email"})
  email?: string;

  @Expose({name: "tel"})
  tel?: string;

  @Expose({name: "name"})
  name?: string;

  @Expose({name: "password_digest"})
  passwordDigest?: string;

  @Expose({name: "age"})
  age?: number;

  @Expose({name: "status"})
  status?: number;

  @Expose({name: "channel"})
  channel?: string;

  @Expose({name: "introduction"})
  introduction?: string;

  @Expose({name: "important_thing"})
  importantThing?: string;

  @Expose({name: "motivation"})
  motivation?: string;

  @Expose({name: "hobby"})
  hobby?: string;

  @Expose({name: "failed_experience"})
  failedExperience?: string;

  @Expose({name: "sence_of_accomplishment"})
  senceOfAccomplishment?: string;

  @Expose({name: "spending_in_holiday"})
  spendingInHoliday?: string;

  @Expose({name: "dream"})
  dream?: string;

  @Expose({name: "favorite_word"})
  favoriteWord?: string;

  @Expose({name: "seeking_for_work"})
  seekingForWork?: string;

  @Expose({name: "publish_a"})
  publishA: boolean;

  @Expose({name: "publish_b"})
  publishB: boolean;

  @Expose({name: "publish_c"})
  publishC: boolean;

  @Expose({name: "publish_d"})
  publishD: boolean;

  @Expose({name: "job_hunting"})
  jobHunting: boolean;

  @Expose({name: "is_offered"})
  isOffered: boolean;

  @Expose({name: "login_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  loginAt?: Date;

  @Expose({name: "email_verified"})
  emailVerified?: boolean;

  @Expose({name: "student"})
  student?: boolean;
 @Expose({name: "birthday"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  birthday: Date;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class CompanyBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "parent"})
  parent?: number;

  @Expose({name: "email"})
  email: string;

  @Expose({name: "password_digest"})
  passwordDigest: string;

  @Expose({name: "department"})
  department?: string;

  @Expose({name: "business_id"})
  businessId?: number;

  @Expose({name: "recruiter"})
  recruiter?: string;

  @Expose({name: "status"})
  status?: number;

  @Expose({name: "status_changed_at"})
  statusChangedAt: string;

  @Expose({name: "recruiting"})
  recruiting?: boolean;

  @Expose({name: "register_type"})
  registerType?: string;

  @Expose({name: "external_id"})
  externalId?: string;

  @Expose({name: "optional_tickets"})
  optionalTickets?: number;

  @Expose({name: "invitation"})
  invitation?: number;

  @Expose({name: "plan"})
  plan: number;

  @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
   @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;
};

export class ProviderLineBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "account_id"})
  accountId: string;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class InformalOfferBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "user_name"})
  userName: string;

  @Expose({name: "offer_type"})
  offerType: number;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class UserInformalOfferBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "company_name"})
  companyName: string;

  @Expose({name: "offer_type"})
  offerType: number;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class BouncedEmailBase {
  @Expose({name: "address"})
  address: string;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class EmployeeVoiceBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "name"})
  name?: string;

  @Expose({name: "department"})
  department?: string;

  @Expose({name: "join_year"})
  joinYear?: string;

  @Expose({name: "opportunity"})
  opportunity?: string;

  @Expose({name: "worth"})
  worth?: string;

  @Expose({name: "message"})
  message?: string;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class AdminStaffBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "name"})
  name: string;

  @Expose({name: "password_digest"})
  passwordDigest: string;

  @Expose({name: "role"})
  role?: number;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class UserFavoriteBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "company_id"})
  companyId: number;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class UserHavereadBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "message_id"})
  messageId: number;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class UserCareerHistoryBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "user_id"})
  userId?: number;

  @Expose({name: "content"})
  content: string;
 @Expose({name: "date"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  date: Date;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class UserPrefectureBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "prefecture_type"})
  prefectureType: number;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class UserTokenBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "activation_token"})
  activationToken?: string;

  @Expose({name: "reset_password_token"})
  resetPasswordToken?: string;

  @Expose({name: "activation_email_token"})
  activationEmailToken?: string;

  @Expose({name: "unverified_email"})
  unverifiedEmail?: string;

  @Expose({name: "line_nonce"})
  lineNonce?: string;
 @Expose({name: "activation_token_send_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  activationTokenSendAt: Date;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;
 @Expose({name: "reset_password_token_expires_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  resetPasswordTokenExpiresAt: Date;

};

export class UserJobBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "job_type"})
  jobType: number;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class UserQualificationBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "qualification_id"})
  qualificationId?: number;

  @Expose({name: "name"})
  name: string;
 @Expose({name: "date"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  date: Date;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class UserAcademicHistoryBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "school"})
  school: string;

  @Expose({name: "faculty"})
  faculty?: string;

  @Expose({name: "major_faculty"})
  majorFaculty?: string;

  @Expose({name: "status"})
  status?: number;

  @Expose({name: "school_kind"})
  schoolKind?: number;
 @Expose({name: "date"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  date: Date;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class UserAssessmentBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "name"})
  name?: string;

  @Expose({name: "uniq_key"})
  uniqKey: string;

  @Expose({name: "shared_key"})
  sharedKey: string;

  @Expose({name: "status"})
  status?: number;

  @Expose({name: "style_type"})
  styleType?: number;

  @Expose({name: "result"})
  result?: object;

  @Expose({name: "answer"})
  answer?: object;

  @Expose({name: "birth_time_type"})
  birthTimeType?: number;
 @Expose({name: "birthday"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  birthday: Date;
 @Expose({name: "last_reset_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  lastResetAt: Date;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class UserSecretBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "company_name"})
  companyName?: string;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class EmployeeAssessmentBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "email"})
  email: string;

  @Expose({name: "password_digest"})
  passwordDigest?: string;

  @Expose({name: "name"})
  name?: string;

  @Expose({name: "department"})
  department?: string;

  @Expose({name: "status"})
  status?: number;

  @Expose({name: "result"})
  result?: object;

  @Expose({name: "answer"})
  answer?: object;

  @Expose({name: "birth_time_type"})
  birthTimeType?: number;
 @Expose({name: "birthday"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  birthday: Date;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class MatchingScoreBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "skill_fit"})
  skillFit: number;

  @Expose({name: "culture_fit"})
  cultureFit: number;

  @Expose({name: "total"})
  total: number;

  @Expose({name: "employee_included"})
  employeeIncluded?: boolean;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class HelloWorkJobBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "data_id"})
  dataId: string;

  @Expose({name: "priority"})
  priority: number;

  @Expose({name: "required_count"})
  requiredCount: number;

  @Expose({name: "registered_count"})
  registeredCount?: number;

  @Expose({name: "current_page"})
  currentPage?: number;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class UserLikeBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "likable_unit_type"})
  likableUnitType?: string;

  @Expose({name: "likable_unit_id"})
  likableUnitId?: number;

  @Expose({name: "display_name"})
  displayName: string;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class QualificationBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "group_id"})
  groupId: number;

  @Expose({name: "name"})
  name: string;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class CompanyLikeBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "likable_unit_type"})
  likableUnitType?: string;

  @Expose({name: "likable_unit_id"})
  likableUnitId?: number;

  @Expose({name: "display_name"})
  displayName: string;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class MessageBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "sender_type"})
  senderType: string;

  @Expose({name: "content"})
  content: string;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class CompanyFavoriteBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "user_id"})
  userId: number;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class CompanyHavereadBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "user_id"})
  userId: number;

  @Expose({name: "message_id"})
  messageId: number;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class CompanyTokenBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "activation_token"})
  activationToken?: string;

  @Expose({name: "reset_password_token"})
  resetPasswordToken?: string;

  @Expose({name: "activation_email_token"})
  activationEmailToken?: string;

  @Expose({name: "unverified_email"})
  unverifiedEmail?: string;
 @Expose({name: "activation_token_expires_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  activationTokenExpiresAt: Date;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;
 @Expose({name: "reset_password_token_expires_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  resetPasswordTokenExpiresAt: Date;

};

export class CompanyRecruitBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "title"})
  title?: string;

  @Expose({name: "job_description"})
  jobDescription?: string;

  @Expose({name: "skill"})
  skill?: string;

  @Expose({name: "salary"})
  salary?: string;

  @Expose({name: "allowance"})
  allowance?: string;

  @Expose({name: "bonus"})
  bonus?: string;

  @Expose({name: "salary_promotion"})
  salaryPromotion?: string;

  @Expose({name: "employee_benefit"})
  employeeBenefit?: string;

  @Expose({name: "vacation"})
  vacation?: string;

  @Expose({name: "working_time"})
  workingTime?: string;

  @Expose({name: "break_time"})
  breakTime?: string;

  @Expose({name: "in_house_system"})
  inHouseSystem?: string;

  @Expose({name: "placement"})
  placement?: string;

  @Expose({name: "employment_period"})
  employmentPeriod?: string;

  @Expose({name: "trial_period"})
  trialPeriod?: string;

  @Expose({name: "overworking_time"})
  overworkingTime?: string;

  @Expose({name: "number_of_people"})
  numberOfPeople?: string;

  @Expose({name: "selection_flow"})
  selectionFlow?: string;

  @Expose({name: "transportation_expenses"})
  transportationExpenses?: string;

  @Expose({name: "other_expenses"})
  otherExpenses?: string;
  
  @Expose({name: "appearance"})
  appearance?: string;

  @Expose({name: "remote"})
  remote?: boolean;

  @Expose({name: "recruit_type"})
  recruitType?: boolean;

  @Expose({name: "new_graduate"})
  newGraduate?: boolean;

  @Expose({name: "mid_carrer"})
  midCarrer?: boolean;

  @Expose({name: "recruit_deadline"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  recruitDeadline: Date;

  @Expose({name: "job_type"})
  jobType?: number;
  
  @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;

  @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

  @Expose({name: "registered_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  registeredAt: Date;

  @Expose({name: "salary_type"})
  salaryType?: boolean;

  @Expose({name: "salary_base"})
  salaryBase?: string;

  @Expose({name: "salary_lower"})
  salaryLower?: string;

  @Expose({name: "salary_upper"})
  salaryUpper?: string;
};

export class CompanyIntroductionBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "name"})
  name?: string;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class CompanyProfileBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "name"})
  name: string;

  @Expose({name: "address"})
  address?: string;

  @Expose({name: "representative"})
  representative?: string;

  @Expose({name: "capital"})
  capital?: string;

  @Expose({name: "url"})
  url?: string;

  @Expose({name: "twitter_url"})
  twitterUrl?: string;

  @Expose({name: "facebook_url"})
  facebookUrl?: string;

  @Expose({name: "instagram_url"})
  instagramUrl?: string;

  @Expose({name: "tel"})
  tel?: string;

  @Expose({name: "main_client"})
  mainClient?: string;

  @Expose({name: "prefecture_type"})
  prefectureType?: number;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;
 @Expose({name: "founded_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  foundedAt: Date;

};

export class CompanyDetailBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "introduction"})
  introduction?: string;

  @Expose({name: "cherishing"})
  cherishing?: string;

  @Expose({name: "story"})
  story?: string;

  @Expose({name: "vision"})
  vision?: string;

  @Expose({name: "effort"})
  effort?: string;

  @Expose({name: "crisis_episode"})
  crisisEpisode?: string;

  @Expose({name: "activity"})
  activity?: string;

  @Expose({name: "appeal"})
  appeal?: string;

  @Expose({name: "publish_job_desc"})
  publishJobDesc: boolean;

  @Expose({name: "publish_job_procedures"})
  publishJobProcedures: boolean;

  @Expose({name: "publish_ideal_figure"})
  publishIdealFigure: boolean;

  @Expose({name: "publish_required_ability"})
  publishRequiredAbility: boolean;

  @Expose({name: "all_employees"})
  allEmployees?: number;

  @Expose({name: "men"})
  men?: number;

  @Expose({name: "women"})
  women?: number;

  @Expose({name: "teens"})
  teens?: number;

  @Expose({name: "twenties"})
  twenties?: number;

  @Expose({name: "thirties"})
  thirties?: number;

  @Expose({name: "forties"})
  forties?: number;

  @Expose({name: "fifteenth"})
  fifteenth?: number;

  @Expose({name: "after_sixties"})
  afterSixties?: number;

  @Expose({name: "sales"})
  sales?: number;

  @Expose({name: "income"})
  income?: number;

  @Expose({name: "sales_before1"})
  salesBefore1?: number;

  @Expose({name: "sales_before2"})
  salesBefore2?: number;

  @Expose({name: "capital_adequacy_ratio"})
  capitalAdequacyRatio?: number;

  @Expose({name: "new_graduates"})
  newGraduates?: number;

  @Expose({name: "new_graduates_before1"})
  newGraduatesBefore1?: number;

  @Expose({name: "new_graduates_before2"})
  newGraduatesBefore2?: number;

  @Expose({name: "turnovers"})
  turnovers?: number;

  @Expose({name: "turnovers_before1"})
  turnoversBefore1?: number;

  @Expose({name: "turnovers_before2"})
  turnoversBefore2?: number;

  @Expose({name: "avg_duration_of_service"})
  avgDurationOfService?: number;

  @Expose({name: "avg_overtime_hours"})
  avgOvertimeHours?: number;

  @Expose({name: "avg_taken_paid_leaves"})
  avgTakenPaidLeaves?: number;

  @Expose({name: "taken_childcare_leave_men_ratio"})
  takenChildcareLeaveMenRatio?: number;

  @Expose({name: "taken_childcare_leave_women_ratio"})
  takenChildcareLeaveWomenRatio?: number;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class CompanyAssessmentBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "email"})
  email: string;

  @Expose({name: "password_digest"})
  passwordDigest?: string;

  @Expose({name: "name"})
  name?: string;

  @Expose({name: "department"})
  department?: string;

  @Expose({name: "status"})
  status?: number;

  @Expose({name: "result"})
  result?: object;
 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};


export class DirectlyAssessmentBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "code"})
  code: string;

  @Expose({name: "name"})
  name?: string;

  @Expose({name: "email"})
  email: string;

  @Expose({name: "tel"})
  tel: string;

  @Expose({name: "birthday"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  birthday: Date;

  @Expose({name: "result"})
  result?: object;

  @Expose({name: "status"})
  status?: number;

  @Expose({name: "skill_fit"})
  skillFit: number;

  @Expose({name: "culture_fit"})
  cultureFit: number;

  @Expose({name: "total"})
  total: number;

  @Expose({name: "channel"})
  channel?: string;

  @Expose({name: "person_note"})
  person_note?: string;

  @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
    createdAt: Date;
  @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};

export class CompanyWorkplaceBase {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "company_id"})
  companyId: number;

  @Expose({name: "prefecture_type"})
  prefectureType?: number;

  @Expose({name: "address"})
  address?: string;

 @Expose({name: "created_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  createdAt: Date;
 @Expose({name: "updated_at"}) @Transform((value) => dayjs(value).format("YYYY/MM/DD HH:mm:ss"))
  updatedAt: Date;

};