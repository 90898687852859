import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import Attachment from 'domain/model/attachment';
import Base from 'domain/repository/base';

export default class UserAttachmentRepository extends Base {
  async fetchIndex({
    userId,
  }: {
    userId: string;
  }) {
    return this.api.get(endpoint.user.attachments.index(userId), {}).then(res => {
      return res.data.map((r: Attachment) => plainToClass(Attachment, r, { excludeExtraneousValues: true }))
    });
  };
}
