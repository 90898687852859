import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import EmployeeVoice from 'domain/model/employee_voice';
import Base from 'domain/repository/base';

export default class EmployeeVoiceRepository extends Base {
  async fetchIndex({
    companyId,
  }: {
    companyId: string;
  }) {
    return this.api.get(endpoint.company.employeeVoices.index(companyId)).then(res => {
      const response: { voices: EmployeeVoice[]; total: number } = {
        voices: res.data.voices.map((voice: EmployeeVoice) =>
          plainToClass(EmployeeVoice, voice, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      }
      return response;
    });
  };
}
