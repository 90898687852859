import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import UserJob from 'domain/model/user_job';
import Base from 'domain/repository/base';

export default class UserJobRepository extends Base {
  async fetchIndex({
    userId,
    page,
  }: {
    userId: string;
    page?: number;
  }) {
    return this.api.get(endpoint.user.Job.index(userId), {params: {page}}).then(res => {
      const response: { jobs: UserJob[]; total: number } = {
        jobs: res.data.jobs.map((job: UserJob) =>
          plainToClass(UserJob, job, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };
}
