import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
} from '@material-ui/core';

type Props = {
  title: string;
  total: number;
  totalToday?: number | null;
};

export const TotalCard: React.FC<Props> = ({
  title,
  total,
  totalToday,
}) => {
  return (
    <>
      <Card>
        <CardHeader
          title={title}
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <Typography color="textSecondary" gutterBottom>
                全期間
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography color="textSecondary" align="right" gutterBottom>
                {total}
              </Typography>
            </Grid>
          </Grid>
            { totalToday != null ? (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={5}>
                  <Typography color="textSecondary" gutterBottom>
                    本日
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography color="textSecondary" align="right" gutterBottom>
                    {totalToday}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
        </CardContent>
      </Card>
    </>
  );
}
