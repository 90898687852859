import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import CompanyDetail from 'domain/model/company_detail';
import Base from 'domain/repository/base';

export default class CompanyDetailRepository extends Base {
  async fetchShow({
    companyId,
  }: {
    companyId: string;
  }) {
    return this.api.get(endpoint.company.detail.show(companyId)).then(res => {
      return plainToClass(CompanyDetail, res.data.detail, { excludeExtraneousValues: true })
    });
  };
}
