import React, { useEffect, useState, useCallback } from "react";
import { RowsProp, DataGrid, CellParams } from '@material-ui/data-grid';
import clsx from 'clsx';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  IconButton,
  Collapse,
  Button,
} from '@material-ui/core';
import {
  ExpandMore,
} from '@material-ui/icons';
import { useParams, useHistory, generatePath } from "react-router-dom";

import {
  UserRepository,
  UserAssessmentRepository,
  UserAttachmentRepository,
} from 'domain/repository';
import User from 'domain/model/user';
import UserAssessment from 'domain/model/user_assessment';
import Attachment from 'domain/model/attachment';
import { CardTable } from 'components/Table/CardTable';
import {
  UserFieldDefinition,
  UserAssessmentFieldDefinition,
} from 'domain/model/field_difinition'
import { useRepository } from 'components/lib/Repository';
import { CustomPagination } from 'components/Pagination';
import { useIndex } from 'hooks/useIndex';
import routes from 'utils/routes';

type IdParams = {
  id: string;
};

export const UserShow = () => {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams<IdParams>();
  const [user, setUser] = useState<User | null>(null);
  const [userAssessment, setUserAssessment] = useState<UserAssessment | null>(null);
  const [userAttachments, setUserAttachments] = useState<Attachment[]>([]);
  const [rows, setRows] = useState<RowsProp>([]);
  const userRepository = useRepository(UserRepository);
  const userAttachmentRepository = useRepository(UserAttachmentRepository);
  const userAssessmentRepository = useRepository(UserAssessmentRepository);
  const {
    page,
    totalPage,
    sort,
    sortingMode,
    handlePageChange,
    handleSortChange,
    changeTotal,
  } = useIndex();

  const handleClickCell = (param: CellParams): void => {
    const { field, value } = param;
    if (field === 'id') {
      const path = generatePath(routes.company.companyShow.path, {id: value as number});
      history.push(path);
    }
  }

  useEffect(() => {
    userRepository.fetchShow({id}).then(res => {
      setUser(res);
      userAssessmentRepository.fetchShow({id: String(res.assessmentId)}).then(res => {
        setUserAssessment(res);
      })
    })
    userAttachmentRepository.fetchIndex({userId: id}).then(res => {
      setUserAttachments(res);
    })
  }, [id])

  useEffect(() => {
    userRepository.fetchFavoriteCompanyIndex({userId: id, page, sort}).then(res => {
      const { total } = res;
      changeTotal(total);
      setRows(res.companies);
    });
  }, [id, page, sort]);

  const handleClickFactsheet = () => {
    const path = generatePath(routes.user.userFactsheetShow.path, {id: id})
    history.push(path);
  };

  return (
    <>
      <Toolbar>
        <Typography className={classes.marginRight}>ユーザー詳細</Typography>
        <Button variant="contained" color="primary" onClick={handleClickFactsheet}>ファクトシートへ</Button>
      </Toolbar>
      <CardTable
        title="ユーザー情報"
        model={user}
        fieldDefinition={UserFieldDefinition}
      />
      <CardTable
        title="アセスメント結果"
        model={userAssessment}
        fieldDefinition={UserAssessmentFieldDefinition}
      />
      <ImageCard attachments={userAttachments}/>

      <Card>
        <CardHeader title="お気に入り企業一覧" />
        <CardContent style={{ width: '100%', minHeight: 600 }}>
          <DataGrid
            rows={rows}
            columns={[
              {
                field: 'id',
                width: 100,
                sortable: false,
              },
              {
                field: 'name',
                width: 250,
                sortable: false,
              },
              {
                field: 'email',
                width: 200,
                headerName:"メールアドレス",
                sortable: false,
              },
              {
                field: 'department',
                width: 200,
                headerName:"求人を行う部署",
                sortable: false,
              },
              {
                field: 'businessId',
                width: 200,
                sortable: false,
              },
              {
                field: 'business',
                width: 200,
                headerName:"企業の業界・業種",
                sortable: false,
              },
              {
                field: 'status',
                width: 200,
                headerName:"ステータス",
                sortable: false,
              },
              {
                field: 'isOffered',
                headerName:"オファーを受ける",
                width: 200,
                sortable: false,
              },
            ]}
            pagination
            rowCount={totalPage}
            paginationMode="server"
            sortingMode={sortingMode}
            onCellClick={handleClickCell}
            onPageChange={handlePageChange}
            onSortModelChange={handleSortChange}
            components={{pagination: CustomPagination}}
            rowsPerPageOptions={[100]}
            disableColumnResize={true}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
            loading={false}
          />
        </CardContent>
      </Card>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginBottom: theme.spacing(5),
    },
    innerMargin: {
      marginBottom: theme.spacing(2),
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    logo: {
      marginRight: 'auto',
      display: 'block',
      maxWidth: '30%',
    },
    image: {
      marginRight: 'auto',
      display: 'block',
      maxWidth: '100%',
    },
    marginRight: {
      marginRight: 'auto',
    },
  })
);

const ImageCard: React.FC<{
  attachments: Attachment[]
}> = ({
  attachments,
}) => {
  const classes = useStyles();
  const [opened, setOpened] = useState<boolean>(false);

  const logo = attachments.filter((attachment) => attachment.name === "thumbnail")[0];
  const images = attachments.filter((attachment) => attachment.name.startsWith("photos"));

  const switchOpened = useCallback(()=>{
    setOpened(!opened)
  }, [opened]);

  return (
    <Card className={classes.margin}>
      <CardHeader
        title="画像一覧"
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: opened,
            })}
            onClick={switchOpened}
            aria-expanded={opened}
            aria-label="show more"
          >
            <ExpandMore />
          </IconButton>
        }
      />
      <CardContent>
        <Collapse in={opened} timeout="auto" unmountOnExit>
          <Typography className={classes.innerMargin}>サムネイル</Typography>
          <div className={classes.innerMargin}>
            {
              logo ? (<img className={classes.logo} src={logo.url} alt="thumbnail" />)
                : (<Typography>データが存在しません</Typography>)
            }
          </div>
          <Typography className={classes.innerMargin}>あなたを表す写真</Typography>
          {
            images.length !== 0 ? (
              <Grid container spacing={1} justify="flex-start" className={classes.innerMargin}>
                { images.map(attachment => (
                  <Grid item xs={12} sm={5} key={attachment.id}>
                    <img className={classes.image} src={attachment.url} alt="photos" />
                  </Grid>
                ))}
              </Grid>
              ) : (
                <Typography>データが存在しません</Typography>
              )
          }
        </Collapse>
      </CardContent>
    </Card>
  )
};
