import { OperatorTypes } from 'utils/types';

type sortType = 'asc' | 'desc' | null | undefined;

export type Sortable = {
  field: string | null | undefined,
  sort: sortType,
}

export const OperatorType: { [key in OperatorTypes]: string } = {
  EQ: "=",
  LT: "<",
  LTE: "≤",
  GT: ">",
  GTE: "≥",
  NEEQ: "≒",
  NTEQ: "≠",
} as const;

export type Filterable = {
  field: string,
  operator: OperatorTypes,
  value: string,
}
