import React, { useState, useEffect } from "react";
import { RowsProp, DataGrid, RowParams } from '@material-ui/data-grid';
import { useParams } from 'react-router-dom';
import {
  Toolbar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import {
  CompanyRepository,
  CompanyProfileRepository,
  CompanyDetailRepository,
  CompanyRecruitRepository,
  CompanyAttachmentRepository,
  CompanyWorkplaceRepository,
  EmployeeVoiceRepository,
} from 'domain/repository'
import Company from 'domain/model/company';
import CompanyProfile from 'domain/model/company_profile';
import CompanyDetail from 'domain/model/company_detail';
import CompanyRecruit from 'domain/model/company_recruit';
import Attachment from 'domain/model/attachment';
import CompanyWorkplace from 'domain/model/company_workplace';
import EmployeeVoice from 'domain/model/employee_voice';
import { CardTable } from 'components/Table/CardTable';
import { ImageCard } from 'pages/Company/Show/ImageCard';
import {
  CompanyFieldDefinition,
  CompanyProfileFieldDefinition,
  CompanyDetailFieldDefinition,
  CompanyRecruitFieldDefinition,
} from 'domain/model/field_difinition'
import { useRepository } from 'components/lib/Repository';
import { useIndex } from 'hooks/useIndex';
import { CustomPagination } from 'components/Pagination';
import { CustomModal } from 'components/Modal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginTop: theme.spacing(5),
    },
    cardContent: {
      maxHeight: "600px",
      overflow: "scroll",
    }
  })
);

type IdParams = {
  id: string;
};

export const CompanyShow = () => {
  const { id } = useParams<IdParams>();
  const [company, setCompany] = useState<Company | null>(null);
  const [companyProfile, setCompanyProfile] = useState<CompanyProfile | null>(null);
  const [companyDetail, setCompanyDetail] = useState<CompanyDetail | null>(null);
  const [companyRecruit, setCompanyRecruit] = useState<CompanyRecruit | null>(null);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const companyRepository = useRepository(CompanyRepository);
  const companyProfileRepository = useRepository(CompanyProfileRepository);
  const companyDetailRepository = useRepository(CompanyDetailRepository);
  const companyRecruitRepository = useRepository(CompanyRecruitRepository);
  const companyAttachmentRepository = useRepository(CompanyAttachmentRepository);
  const [rows, setRows] = useState<RowsProp>([]);
  const {
    page,
    totalPage,
    sort,
    sortingMode,
    handlePageChange,
    handleSortChange,
    changeTotal,
  } = useIndex();
  const classes = useStyles();

  useEffect(() => {
    companyRepository.fetchShow({id}).then(res => {
      setCompany(res);
    });
    companyProfileRepository.fetchShow({companyId: id}).then(res => {
      setCompanyProfile(res);
    });
    companyDetailRepository.fetchShow({companyId: id}).then(res => {
      setCompanyDetail(res);
    });
    companyRecruitRepository.fetchShow({companyId: id}).then(res => {
      setCompanyRecruit(res);
    });
    companyAttachmentRepository.fetchIndex({companyId: id}).then(res => {
      setAttachments(res);
    });
  }, [id])

  useEffect(() => {
    companyRepository.fetchFavoriteUserIndex({companyId: id, page, sort}).then(res => {
      const { total } = res;
      changeTotal(total);
      setRows(res.users);
    });
  }, [id, page, sort]);

  return (
    <>
      <Toolbar>企業詳細</Toolbar>
      <CardTable
        title="採用担当者情報"
        model={company}
        fieldDefinition={CompanyFieldDefinition.filter(v => v.field !== 'recruiting')}
      />
      <CardTable
        title="企業情報"
        model={companyProfile}
        fieldDefinition={CompanyProfileFieldDefinition}
      />
      <CardTable
        title="会社紹介"
        model={companyDetail}
        fieldDefinition={CompanyDetailFieldDefinition}
      />
      <CardTable
        title="募集要項"
        model={companyRecruit}
        fieldDefinition={CompanyRecruitFieldDefinition}
      />
      <ImageCard attachments={attachments} />



      <Card className={classes.margin}>
        <CardHeader title="お気に入り求職者一覧" />
        <CardContent>
          <div style={{ width: '100%', minHeight: 600 }}>
            <DataGrid
              rows={rows}
              columns={[
                {
                  field: 'id',
                },
                {
                  field: 'name',
                  width: 250,
                  sortable: false,
                  headerName:"求職者名",
                },
                {
                  field: 'email',
                  width: 300,
                  sortable: false,
                  headerName:"メールアドレス",
                },
                {
                  field: 'tel',
                  width: 250,
                  sortable: false,
                  headerName:"電話番号",
                },
                {
                  field: 'status',
                  width: 150,
                  sortable: false,
                  headerName:"ステータス",
                },
                {
                  field: 'createdAt',
                  sortable: false,
                  width: 200,
                  headerName:"登録日",
                },
                {
                  field: 'updatedAt',
                  sortable: false,
                  width: 200,
                  headerName:"更新日",
                },
              ]}
              pagination
              rowCount={totalPage}
              paginationMode="server"
              sortingMode={sortingMode}
              onPageChange={handlePageChange}
              onSortModelChange={handleSortChange}
              components={{pagination: CustomPagination}}
              rowsPerPageOptions={[100]}
              disableColumnResize={true}
              disableColumnMenu={true}
              disableSelectionOnClick={true}
              loading={false}
            />
          </div>
        </CardContent>
      </Card>

      <WorkplaceCard companyId={id} />
      <EmployeeVoiceCard companyId={id} />
    </>
  );
};


const WorkplaceCard: React.FC<{companyId: string}> = ({companyId}) => {
  const companyWorkplaceRepository = useRepository(CompanyWorkplaceRepository);
  const [rows, setRows] = useState<RowsProp>([]);
  const {
    page,
    totalPage,
    handlePageChange,
    changeTotal,
  } = useIndex();
  const [row, setRow] = useState<CompanyWorkplace | null>(null);
  const classes = useStyles();

  useEffect(() => {
    companyWorkplaceRepository.fetchIndex({companyId: companyId}).then(res => {
      changeTotal(res.total)
      setRows(res.places)
    });
  }, [companyId, page])

  return (
    <>
    <Card className={classes.margin}>
      <CardHeader title="就業場所一覧" />
      <CardContent>
        <div style={{ width: '100%', minHeight: 600 }}>
          <DataGrid
            rows={rows}
            columns={[
              {
                field: 'id',
                sortable: false,
              },
              {
                field: 'prefectureType',
                headerName: '都道府県ID',
                sortable: false,
                width: 100,
              },
              {
                field: 'address',
                headerName: '以降の住所',
                sortable: false,
                width: 300,
              },
              {
                field: 'createdAt',
                headerName: '登録日',
                sortable: false,
                width: 200,
              },
              {
                field: 'updatedAt',
                headerName: '更新日',
                sortable: false,
                width: 200,
              },
            ]}
            pagination
            rowCount={totalPage}
            paginationMode="server"
            onPageChange={handlePageChange}
            components={{pagination: CustomPagination}}
            rowsPerPageOptions={[100]}
            disableColumnResize={true}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
            loading={false}
          />
        </div>
      </CardContent>
    </Card>
    </>
  );
};



const EmployeeVoiceCard: React.FC<{companyId: string}> = ({companyId}) => {
  const employeeVoiceRepository = useRepository(EmployeeVoiceRepository);
  const [rows, setRows] = useState<RowsProp>([]);
  const {
    page,
    totalPage,
    handlePageChange,
    changeTotal,
  } = useIndex();
  const [opened, setOpened] = useState<boolean>(false);
  const handleClose = () =>   {
    setOpened(false);
  }
  const [row, setRow] = useState<EmployeeVoice | null>(null);
  const classes = useStyles();

  useEffect(() => {
    employeeVoiceRepository.fetchIndex({companyId: companyId}).then(res => {
      changeTotal(res.total)
      setRows(res.voices)
    });
  }, [companyId, page])

  const handleClickRow = (params: RowParams) => {
    setRow(params.row as EmployeeVoice)
    setOpened(true)
  }

  return (
    <>
    <Card className={classes.margin}>
      <CardHeader title="従業員の声一覧" />
      <CardContent>
        <div style={{ width: '100%', minHeight: 600 }}>
          <DataGrid
            rows={rows}
            columns={[
              {
                field: 'id',
                sortable: false,
              },
              {
                field: 'name',
                headerName: '氏名',
                width: 250,
                sortable: false,
              },
              {
                field: "department",
                headerName: '部署',
                width: 300,
                sortable: false,
              },
              {
                field: "joinYear",
                headerName: '入社日',
                width: 250,
                sortable: false,
              },
              {
                field: 'createdAt',
                headerName: '登録日',
                sortable: false,
                width: 200,
              },
              {
                field: 'updatedAt',
                headerName: '更新日',
                sortable: false,
                width: 200,
              },
            ]}
            pagination
            rowCount={totalPage}
            paginationMode="server"
            onPageChange={handlePageChange}
            components={{pagination: CustomPagination}}
            onRowClick={handleClickRow}
            rowsPerPageOptions={[100]}
            disableColumnResize={true}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
            loading={false}
          />
        </div>
      </CardContent>
    </Card>
    <CustomModal
      opened={opened}
      handleClose={handleClose}
    >
      <CardContent className={classes.cardContent}>
      <Grid container spacing={3} style={{padding: '20px'}}>
        <Grid item xs={4}>
          <Typography>写真</Typography>
        </Grid>
        <Grid item xs={8}>
          <img src={row?.attachment?.url} alt="" style={{maxHeight: "100px", maxWidth: "100px"}} />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{padding: '20px'}}>
        <Grid item xs={4}>
          <Typography>入社のきっかけ</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ overflowWrap: 'break-word', maxWidth: '750px' }}>{row?.opportunity}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{padding: '20px'}}>
        <Grid item xs={4}>
          <Typography>仕事でやりがいを感じる時</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ overflowWrap: 'break-word', maxWidth: '750px' }}>{row?.worth}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{padding: '20px'}}>
        <Grid item xs={4}>
          <Typography>後輩へのメッセージ</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ overflowWrap: 'break-word', maxWidth: '750px' }}>{row?.message}</Typography>
        </Grid>
      </Grid>
      </CardContent>
    </CustomModal>
    </>
  );
};
