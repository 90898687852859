import axios from "axios";

import { getToken } from 'utils/storage';

const headers = {
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
  Authorization: "",
};

const getBaseURL = () => {
  if (process.env.NODE_ENV === "development") {
      return "http://localhost:3000";
  }

  return "";
};

const token = getToken() || null;

if (token) {
  headers.Authorization = `Bearer ${token}`;
}

const api = axios.create({
  baseURL: getBaseURL(),
  headers,
  responseType: "json",
});

const changeToken = (newToken: string) => {
  api.defaults.headers.common.Authorization = `Bearer ${newToken}`;
};

export { getBaseURL, changeToken };
export default api;
