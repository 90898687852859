import React, { useState, useEffect } from "react";
import { RowsProp, DataGrid, PageChangeParams, SortModelParams, CellParams } from '@material-ui/data-grid';
import {
  Toolbar,
} from '@material-ui/core';
import { useHistory, generatePath } from "react-router-dom";

import { UserAssessmentRepository } from 'domain/repository';
import { useRepository } from 'components/lib/Repository';
import { CustomPagination } from 'components/Pagination';
import { useFilter } from "hooks/useFilter";
import { FilterForm } from 'components/Filter';
import { UserAssessmentFieldDefinition } from 'domain/model/field_difinition';
import { useIndex } from 'hooks/useIndex';
import routes from "utils/routes";

const ignoreFields = ["result"]
const userAssessmentFieldDefinition = UserAssessmentFieldDefinition.filter(v => !ignoreFields.includes(v.field) )

export const UserAssessmentIndex = () => {
  const history = useHistory();
  const userAssessmentRepository = useRepository(UserAssessmentRepository);
  const [rows, setRows] = useState<RowsProp>([]);
  const {
    page,
    totalPage,
    sort,
    sortingMode,
    handlePageChange,
    handleSortChange,
    changeTotal,
  } = useIndex();
  const { filters, setFilters, handleSetFilters, } = useFilter();

  const handleClickCell = (param: CellParams): void => {
    const { field, value } = param;
    if (field === 'id') {
      const path = generatePath(routes.user.userAssessmentShow.path, {id: value as number});
      history.push(path);
    }
  }

  useEffect(() => {
    userAssessmentRepository.fetchIndex({page, sort, filters: filters}).then(res => {
      const { total } = res;
      changeTotal(total);
      setRows(res.assessments);
    });
  }, [page, sort, filters]);

  return (
    <>
      <Toolbar>アセスメント一覧</Toolbar>
      <FilterForm
        handleFilter={handleSetFilters}
        fieldDefinition={userAssessmentFieldDefinition}
      />
      <div style={{ width: '100%', minHeight: 600 }}>
        <DataGrid
          rows={rows}
          columns={[
            {
              field: 'id',
            },
            {
              field: 'name',
              width: 250,
              sortable: false,
            },
            {
              field: 'status',
              width: 200,
              sortable: false,
            },
            {
              field: 'uniqKey',
              width: 250,
              sortable: false,
            },
            {
              field: 'sharedKey',
              width: 250,
              sortable: false,
            },
            {
              field: 'styleType',
              width: 200,
              sortable: false,
            },
            {
              field: 'createdAt',
              width: 200,
              sortable: false,
            },
            {
              field: 'updatedAt',
              width: 200,
              sortable: false,
            },
          ]}
          pagination
          rowCount={totalPage}
          paginationMode="server"
          sortingMode={sortingMode}
          onCellClick={handleClickCell}
          onPageChange={handlePageChange}
          onSortModelChange={handleSortChange}
          components={{pagination: CustomPagination}}
          rowsPerPageOptions={[100]}
          disableColumnResize={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          loading={false}
        />
      </div>
    </>
  );
};
