import React, { useEffect, useState } from "react";
import {
  Toolbar,
} from '@material-ui/core';
import { useParams } from "react-router-dom";

import { DirectlyAssessmentRepository } from 'domain/repository';
import DirectlyAssessment from 'domain/model/directly_assessment';
import { useLoading } from 'components/lib/LoadingProvider';
import { CardTable } from 'components/Table/CardTable';
import {
  DirectlyAssessmentFieldDefinition,
} from 'domain/model/field_difinition'
import { useRepository } from 'components/lib/Repository';

type IdParams = {
  id: string;
};

export const DirectlyAssessmentShow = () => {
  const { id } = useParams<IdParams>();
  const [directlyAssessment, setDirectlyAssessment] = useState<DirectlyAssessment | null>(null);
  const directlyAssessmentRepository = useRepository(DirectlyAssessmentRepository);

  useEffect(() => {
    directlyAssessmentRepository.fetchShow({id}).then(res => {
      setDirectlyAssessment(res);
    })
  }, [id])

   return (
    <>
      <Toolbar>候補者アセスメント詳細</Toolbar>
      <CardTable
        title="候補者アセスメント"
        model={directlyAssessment}
        fieldDefinition={DirectlyAssessmentFieldDefinition}
      />
    </>
  );
};
