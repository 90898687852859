import { Expose } from "class-transformer";

export default class Attachment {
  @Expose({name: "id"})
  id: number;

  @Expose({name: "name"})
  name: string;

  @Expose({name: "record_type"})
  recordType: string;

  @Expose({name: "signed_id"})
  signedId: string;

  @Expose({name: "filename"})
  filename: string;

  @Expose({name: "url"})
  url: string;
};
