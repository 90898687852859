import { useSnackbar } from "notistack";

export const hasNotifyError = (data: any): data is { notify_error: Error } => {
  return data && data.notify_error !== undefined
    && ('string' === typeof data.notify_error || Array.isArray(data.notify_error))
}

export type Error = string | string[];

export const useError = () => {
  const { enqueueSnackbar } = useSnackbar()
  const enqueueError = (error: Error) => {
    if (typeof error === "string") {
      enqueueSnackbar(
        error,
        {
          variant: 'error',
        },
      );
    } else {
      for (let message of error) {
        enqueueSnackbar(
          message,
          {
            variant: 'error',
          },
        )
      }
    }
  }
  return {
    enqueueError,
  }
}
