import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import Base from 'domain/repository/base';
import { removeToken } from 'utils/storage';
import AdminStaff from 'domain/model/admin_staff';

export default class AuthService extends Base {
  async fetchMe() {
    return this.api.get(endpoint.common.me.show(), {}).then(res => {
      const staff: AdminStaff = plainToClass(AdminStaff, res.data, { excludeExtraneousValues: true });
      return staff;
    }).catch((err) => {
      removeToken();
      return Promise.reject(err);
    })
  }
}
