import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import CompanyAssessment from 'domain/model/company_assessment';
import { Sortable, Filterable } from 'domain/repository/params';
import Base from 'domain/repository/base';

export default class CompanyAssessmentRepository extends Base {
  async fetchIndex({
    page,
    sort,
    filters,
  }: {
    page?: number;
    sort?: Sortable | null;
    filters?: Filterable[];
  }) {
    return this.api.get(endpoint.assessment.company.index(), {params: {page, ...sort, filters: filters}}).then(res => {
      const response: { assessments: CompanyAssessment[]; total: number } = {
        assessments: res.data.assessments.map((assessment: CompanyAssessment) =>
          plainToClass(CompanyAssessment, assessment, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };

  async fetchShow({
    id,
  }: {
    id: string;
  }) {
    return this.api.get(endpoint.assessment.company.show(id)).then(res => {
      return plainToClass(CompanyAssessment, res.data.assessment, { excludeExtraneousValues: true })
    });
  };
}
