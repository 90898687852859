import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import AdminStaff from 'domain/model/admin_staff';
import { Sortable, Filterable } from 'domain/repository/params';
import Base from 'domain/repository/base';

export default class AdminStaffRepository extends Base {
  async fetchIndex({
    page,
    sort,
    filters,
  }: {
    page?: number;
    sort?: Sortable | null;
    filters?: Filterable[];
  }) {
    return this.api.get(endpoint.common.adminStaff.index(), {params: {page, ...sort, filters: filters}}).then(res => {
      const response: { admin_staffs: AdminStaff[]; total: number } = {
        admin_staffs: res.data.admin_staffs.map((adminStaff: AdminStaff) =>
          plainToClass(AdminStaff, adminStaff, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };

  async create (name: string, password: string, passwordConfirmation: string) {
    return this.api.post(endpoint.common.adminStaff.create(),{admin_staff:{name, password, passwordConfirmation} })
  }

  async update (
    name: string,
  ) {
    return this.api.patch(endpoint.common.adminStaff.update(),{admin_staff:{name}}).then(res =>{
      const staff: AdminStaff = plainToClass(AdminStaff, res.data, { excludeExtraneousValues: true });
      return staff;
    })
  }

  async destroy (id: string) {
    return this.api.delete(endpoint.common.adminStaff.destroy(id))
  };
}