import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import UserPrefecture from 'domain/model/user_prefecture';
import Base from 'domain/repository/base';

export default class UserPrefectureRepository extends Base {
  async fetchIndex({
    userId,
    page,
  }: {
    userId: string;
    page?: number;
  }) {
    return this.api.get(endpoint.user.Prefecture.index(userId), {params: {page}}).then(res => {
      const response: { prefectures: UserPrefecture[]; total: number } = {
        prefectures: res.data.prefectures.map((prefecture: UserPrefecture) =>
          plainToClass(UserPrefecture, prefecture, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };
}
