import axios, { AxiosInstance } from 'axios';

import { getToken } from 'utils/storage';
import { getBaseURL } from 'utils/api'
import { useLoading } from 'components/lib/LoadingProvider';
import { useError, hasNotifyError } from "hooks/useError";

const blobConvertAsJson = async (blob: Blob) => {
  const fileReader = new FileReader();
  const result = await new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject();
    };

    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsText(blob);
  });
  if (typeof result === 'string') {
    return JSON.parse(result);
  } else {
    throw new Error("Can't convert as json");
  }
};

export const useService = <T extends {}>(service: { new(_: AxiosInstance): T }): T => {
  const { startLoading, endLoading } = useLoading();
  const { enqueueError } = useError();

  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Authorization: `Bearer ${getToken()}`,
  };

  const api = axios.create({
    baseURL: getBaseURL(),
    headers,
  });

  api.interceptors.request.use(req => {
    startLoading()
    return req;
  });

  api.interceptors.response.use(
    (res) => {
      endLoading();
      return res;
    },
    async (err) => {
      let { data } = err.response;
      if (err.request.responseType === "blob") {
        data = await blobConvertAsJson(data);
      }
      if (hasNotifyError(data)) {
        enqueueError(data.notify_error)
      }
      // 共通処理はここに記載
      endLoading();
      return Promise.reject(err);
    }
  );

  return new service(api);
}
