import React, { useState, useEffect } from "react";
import {
  Grid,
  Toolbar,
} from '@material-ui/core';

import { TotalCard } from 'pages/Dashboard/TotalCard'
import {
  CompanyRepository,
  MessageRepository,
  InformalOfferRepository,
  UserRepository,
  UserAssessmentRepository,
} from 'domain/repository';
import {
  Count,
} from 'domain/repository/res';
import { useRepository } from 'components/lib/Repository';

export const Dashboard = () => {
  const [userCount, setUserCount] = useState<Count>({total: 0, totalToday: null});
  const [companyCount, setCompanyCount] = useState<Count>({total: 0, totalToday: null});
  const [messageCount, setMessageCount] = useState<Count>({total: 0});
  const [userAssessmentCount, setUserAssessmentCount] = useState<Count>({total: 0, totalToday: null});
  const [informalOfferCount, setInformalOfferCount] = useState<Count>({total: 0});
  const userRepository = useRepository(UserRepository);
  const companyRepository = useRepository(CompanyRepository);
  const messageRepository = useRepository(MessageRepository);
  const informalOfferRepository = useRepository(InformalOfferRepository);
  const userAssessmentRepository = useRepository(UserAssessmentRepository);

  useEffect(() => {
    let cleanedUp = false;
    const fetchCompanyCount = () => {
      companyRepository.fetchCount().then(res => {
        if (!cleanedUp) { setCompanyCount(res) };
      });
    }
    const fetchUserCount = () => {
      userRepository.fetchCount().then(res => {
        if (!cleanedUp) { setUserCount(res) };
      })
    }
    const fetchMessageCount = () => {
      messageRepository.fetchCount().then(res => {
        if (!cleanedUp) { setMessageCount(res) };
      });
    }
    const fetchInformalOfferCount = () => {
      informalOfferRepository.fetchCount().then(res => {
        if (!cleanedUp) { setInformalOfferCount(res) };
      });
    }
    const fetchUserAssessmentCount = () => {
      userAssessmentRepository.fetchCount().then(res => {
        if (!cleanedUp) { setUserAssessmentCount(res) };
      });
    }
    fetchCompanyCount();
    fetchUserCount();
    fetchMessageCount();
    fetchInformalOfferCount();
    fetchUserAssessmentCount();

    return () => {
      cleanedUp = true;
    }
  }, []);

  return (
    <>
      <Toolbar>ダッシュボード</Toolbar>
      <Grid container spacing={5}>
        <Grid item xs={12} md={5} lg={3}>
          <TotalCard title="ユーザ数" total={userCount.total} totalToday={userCount.totalToday} />
        </Grid>
        <Grid item xs={12} md={5} lg={3}>
          <TotalCard title="企業数" total={companyCount.total} totalToday={companyCount.totalToday} />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={5} lg={3}>
          <TotalCard title="メッセージ数" total={messageCount.total} />
        </Grid>
        <Grid item xs={12} md={5} lg={3}>
          <TotalCard title="内定報告数" total={informalOfferCount.total} />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={5} lg={3}>
          <TotalCard title="求職者診断者数" total={userAssessmentCount.total} totalToday={userAssessmentCount.totalToday} />
        </Grid>
      </Grid>
    </>
  );
};
