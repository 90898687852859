import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import Company from 'domain/model/company';
import User from 'domain/model/user';
import { Sortable, Filterable } from 'domain/repository/params';
import { Count } from 'domain/repository/res';
import Base from 'domain/repository/base';

export default class CompanyRepository extends Base {
  async fetchIndex({
    page,
    sort,
    filters,
  }: {
    page?: number;
    sort?: Sortable | null;
    filters?: Filterable[];
  }) {
    return this.api.get(endpoint.company.company.index(), {params: {page, ...sort, filters: filters}}).then(res => {
      const response: { companies: Company[]; total: number } = {
        companies: res.data.companies.map((company: Company) =>
          plainToClass(Company, company, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };

  async fetchShow({
    id,
  }: {
    id: string;
  }) {
    return this.api.get(endpoint.company.company.show(id)).then(res => {
      return plainToClass(Company, res.data.company, { excludeExtraneousValues: true })
    });
  };

  async fetchCount() {
    return this.api.get(endpoint.company.company.total()).then(res => {
      return {
        total: res.data.total,
        totalToday: res.data.total_today,
      } as Count;
    });
  };

  async fetchFavoriteUserIndex({
    companyId,
    sort,
    page,
  }: {
    companyId: string;
    sort?: Sortable | null;
    page?: number;
  }) {
    return this.api.get(endpoint.company.favoriteUser.index(companyId), {params: {page, ...sort}}).then(res => {
      const response: { users: User[]; total: number } = {
        users: res.data.users.map((user: User) =>
          plainToClass(User, user, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };

}
