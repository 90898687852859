import React, { useEffect, useState } from "react";
import { RowsProp, DataGrid } from '@material-ui/data-grid';
import {
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { useIndex } from 'hooks/useIndex';
import { useRepository } from 'components/lib/Repository';
import { CustomPagination } from 'components/Pagination';
import { UserAcademicHistoryRepository } from 'domain/repository';

type Props = {
  userId: string,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginBottom: theme.spacing(5),
    },
  })
);

export const UserAcademicHistoryIndex: React.FC<Props> = ({userId}) => {
  const classes = useStyles();
  const repo = useRepository(UserAcademicHistoryRepository);
  const [rows, setRows] = useState<RowsProp>([]);
  const {
    page,
    totalPage,
    handlePageChange,
    changeTotal,
  } = useIndex();

  useEffect(() => {
    repo.fetchIndex({userId, page}).then(res => {
      const { total } = res;
      changeTotal(total);
      setRows(res.academicHistories);
    })
  }, [userId])

  return (
    <Card className={classes.margin}>
      <CardHeader title="学歴一覧" />
      <CardContent style={{ width: '100%', minHeight: 600 }}>
        <DataGrid
          rows={rows}
          columns={[
            {
              field: 'id',
              sortable: false,
            },
            {
              field: 'school',
              headerName: '学校名',
              width: 150,
              sortable: false,
            },
            {
              field: 'faculty',
              headerName: '学部',
              width: 150,
              sortable: false,
            },
            {
              field: 'majorFaculty',
              headerName: '学科',
              width: 200,
              sortable: false,
            },
            {
              field: 'status',
              headerName: '状況',
              width: 150,
              sortable: false,
            },
            {
              field: 'schoolKind',
              headerName: '学校種別',
              width: 150,
              sortable: false,
            },
            {
              field: 'date',
              headerName: '年月',
              width: 200,
              sortable: false,
            },
            {
              field: 'createdAt',
              headerName: '登録日',
              width: 200,
              sortable: false,
            },
            {
              field: 'updatedAt',
              headerName: '更新日',
              width: 200,
              sortable: false,
            },
          ]}
          pagination
          rowCount={totalPage}
          paginationMode="server"
          onPageChange={handlePageChange}
          components={{pagination: CustomPagination}}
          rowsPerPageOptions={[100]}
          disableColumnResize={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          loading={false}
        />
      </CardContent>
    </Card>
  );
}
