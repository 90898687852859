import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import UserSecret from 'domain/model/user_secret';
import Base from 'domain/repository/base';

export default class UserSecretRepository extends Base {
  async fetchIndex({
    userId,
    page,
  }: {
    userId: string;
    page?: number;
  }) {
    return this.api.get(endpoint.user.Secret.index(userId), {params: {page}}).then(res => {
      const response: { secrets: UserSecret[]; total: number } = {
        secrets: res.data.secrets.map((secret: UserSecret) =>
          plainToClass(UserSecret, secret, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };
}
