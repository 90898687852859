import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import Message from 'domain/model/message';
import { Sortable, Filterable } from 'domain/repository/params';
import { Count } from 'domain/repository/res';
import Base from 'domain/repository/base';

export default class MessageRepository extends Base {
  async fetchIndex({
    page,
    sort,
    filters,
    official,
  }: {
    page?: number;
    sort?: Sortable | null;
    filters?: Filterable[];
    official?: boolean;
  }) {
    const path = official ? endpoint.common.officialMessage.index() : endpoint.common.message.index();
    return this.api.get(path, {params: {page, ...sort, filters: filters}}).then(res => {
      const response: { messages: Message[]; total: number } = {
        messages: res.data.messages.map((message: Message) =>
          plainToClass(Message, message, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };

  async fetchCount() {
    return this.api.get(endpoint.common.message.total()).then(res => {
      return {
        total: res.data.total,
      } as Count;
    });
  };
}
