import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Button,
  FormGroup,
} from '@material-ui/core';
import  {
  AccountCircle,
  VpnKey,
} from '@material-ui/icons';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'components/lib/AuthProvider';
import api from 'utils/api';
import endpoint from 'utils/endpoint';
import { setToken } from 'utils/storage';
import routes from 'utils/routes';
import { useError, hasNotifyError } from "hooks/useError";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    card: {
      width: "30vw",
      minWidth: 240,
      padding: theme.spacing(1),
    }
  }),
);

type LoginFormBody = {
  name: string;
  password: string;
};

export const Login = () => {
  const { enqueueError } = useError();
  const classes = useStyles();
  const history = useHistory();
  const { staff } = useAuth();
  const [form, setForm] = useState<LoginFormBody>({name: "", password: ""});

  useEffect(() => {
    if (staff !== null) {
      history.push(routes.common.dashboard.path);
    }
  }, []);

  // TODO serviceかどこかに移行
  const login = useCallback(() => {
    api.post(endpoint.common.login.create(), form).then(res => {
      const { data } = res;
      setToken(data.token);
      history.push(routes.common.dashboard.path);
    }).catch(err => {
      const { data } = err.response;
      if (hasNotifyError(data)) {
        enqueueError(data.notify_error)
      }
    });
  }, [form]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value });
    },
    [form, setForm]
  );

  return (
    <>
      <Box display="flex" justifyContent="center" m={3} p={3}>
        <Box p={3}>
          <Card className={classes.card}>
            <CardHeader
              title="ログイン"
            />
            <CardContent>
              <LoginForm login={login} body={form} handleChange={handleChange} />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
}

type Props = {
  login: () => void;
  body: LoginFormBody;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const LoginForm: React.FC<Props> = ({
  login,
  body,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <FormGroup>
      <FormControl className={classes.margin}>
        <InputLabel htmlFor="name">ユーザ名</InputLabel>
        <Input
          id="name"
          type="text"
          onChange={handleChange}
          name="name"
          startAdornment={
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl className={classes.margin}>
        <InputLabel htmlFor="password">パスワード</InputLabel>
        <Input
          id="password"
          type="password"
          onChange={handleChange}
          name="password"
          startAdornment={
            <InputAdornment position="start">
              <VpnKey />
            </InputAdornment>
          }
        />
      </FormControl>
      <Button variant="contained" color="primary" className={classes.margin} onClick={login} >Login</Button>
    </FormGroup>
  )
};
