import React from 'react'
import { useHistory } from "react-router-dom";
import { 
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  TextField,
  Button,
} from '@material-ui/core';
import  {
  AccountCircle,
  VpnKey,
} from '@material-ui/icons';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useForm } from "react-hook-form";

import routes from 'utils/routes';
import { AdminStaffRepository } from 'domain/repository';
import { useRepository } from 'components/lib/Repository';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      margin: theme.spacing(2),
      width: "25vw",
    },
    grid: {
      height: "70vh",
    },
  }),
);

type RegistrationFormBody = {
  name: string;
  password: string;
  passwordConfirmation: string;
};

export const AdminStaffCreate = () => {
  const classes = useStyles();
  const history = useHistory();
  const adminStaffRepository = useRepository(AdminStaffRepository);

  const create =(form: RegistrationFormBody) => {
    return adminStaffRepository.create(form.name, form.password, form.passwordConfirmation)
    .then(
      function() { return  history.push(routes.common.adminStaffIndex.path) }
    )
  }

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.grid}
      >
        <Card>
          <CardHeader
            title="新規管理者登録"
          />
          <CardContent>
            <RegistrationForm create={create} />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

type Props = {
  create: (form: RegistrationFormBody) => void;
};

export const RegistrationForm: React.FC<Props> = ({ create }) => {
  const classes = useStyles();
  const { register, handleSubmit, control, errors, getValues } = useForm<RegistrationFormBody>();

  return (
    <>
      <form onSubmit={handleSubmit(create)}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <TextField
            type="name"
            name="name"
            label="ログインID"
            className={classes.form}
            inputRef={register({ required: "入力してください"})}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type="password"
            name="password"
            label="パスワード"
            className={classes.form}
            inputRef={register({ required: "入力してください"})}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKey />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type="password"
            name="passwordConfirmation"
            label="確認用パスワード"
            className={classes.form}
            inputRef={register({
              required: "入力してください",
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();
                  return password === value || 'パスワードが一致しません';
                },
              }
            })}
            error={Boolean(errors.passwordConfirmation)}
            helperText={errors.passwordConfirmation?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKey />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary" className={classes.form} type="submit">登録</Button>
        </Grid>
      </form>
    </>
  )
}