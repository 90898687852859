import React, { useEffect, useState } from "react";
import {
  Toolbar,
} from '@material-ui/core';
import { useParams } from "react-router-dom";

import { EmployeeAssessmentRepository } from 'domain/repository';
import EmployeeAssessment from 'domain/model/employee_assessment';
import { useLoading } from 'components/lib/LoadingProvider';
import { CardTable } from 'components/Table/CardTable';
import {
  EmployeeAssessmentFieldDefinition,
} from 'domain/model/field_difinition'
import { useRepository } from 'components/lib/Repository';

type IdParams = {
  id: string;
};

export const EmployeeAssessmentShow = () => {
  const { id } = useParams<IdParams>();
  const [employeeAssessment, setEmployeeAssessment] = useState<EmployeeAssessment | null>(null);
  const employeeAssessmentRepository = useRepository(EmployeeAssessmentRepository);

  useEffect(() => {
    employeeAssessmentRepository.fetchShow({id}).then(res => {
      setEmployeeAssessment(res);
    })
  }, [id])

   return (
    <>
      <Toolbar>従業員向けアセスメント詳細</Toolbar>
      <CardTable
        title="従業員アセスメント"
        model={employeeAssessment}
        fieldDefinition={EmployeeAssessmentFieldDefinition}
      />
    </>
  );
};
