import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import CompanyRecruit from 'domain/model/company_recruit';
import Base from 'domain/repository/base';

export default class CompanyRecruitRepository extends Base {
  async fetchShow({
    companyId,
  }: {
    companyId: string;
  }) {
    return this.api.get(endpoint.company.recruit.show(companyId)).then(res => {
      return plainToClass(CompanyRecruit, res.data.recruit, { excludeExtraneousValues: true })
    });
  };
}
