import React, { useState, useCallback } from "react";
import {
  List,
  ListItem,
  Toolbar,
  ListItemIcon,
  ListItemText,
  Collapse,
  Drawer,
  Divider,
} from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";
import { grey } from '@material-ui/core/colors';
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import {
  sidebarMenus,
  SidebarConfig,
} from "components/Layout/sidebarConfig";

type MenuItemProps = {
  main: SidebarConfig;
};

export const sidebarWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: sidebarWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: sidebarWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    subMenu: {
      paddingLeft: theme.spacing(4),
    },
    menu: {
      background: grey[50],
    },
  })
);

export const Sidebar: React.FC = () => {
  const classes = useStyles();
  const menus = sidebarMenus();

  return (
    <Drawer
      variant="permanent"
      open
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <List>
        {menus.map((main, index) => (
          <MenuItem key={index} main={main} />
        ))}
      </List>
    </Drawer>
  );
};

const MenuItem: React.FC<MenuItemProps> = ({
  main,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const history = useHistory();
  const handleLink = (path: string) => history.push(path);

  const handleMainClick = useCallback(() => {
    if (main.subMenu) {
      setOpen(!open);
    } else if (main.path) {
      handleLink(main.path);
    }
  }, [open]);

  return (
    <div>
      <ListItem
        button
        className={classes.menu}
        onClick={() => handleMainClick()}
      >
        <ListItemIcon>
          <main.icon/>
        </ListItemIcon>
        <ListItemText primary={main.title} />
        {main.subMenu && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      {
        main.subMenu &&
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {main.subMenu.map((sub, index) => (
              <ListItem
                key={index}
                button onClick={() => handleLink(sub.path)}
                className={classes.subMenu}
              >
                <ListItemIcon>
                  <sub.icon/>
                </ListItemIcon>
                <ListItemText primary={sub.title}/>
              </ListItem>
            ))}
          </List>
        </Collapse>
      }
      <Divider />
    </div>
  );
};
