const endpoint = {
  common: {
    login: {
      create: () => "/api/login",
    },
    me: {
      show: () => "/api/me",
    },
    message: {
      index: () => "/api/messages",
      total: () => "/api/total/messages",
    },
    officialMessage: {
      index: () => "/api/official_messages",
    },
    informalOffer: {
      index: () => "/api/informal_offers",
      total: () => "/api/total/informal_offers",
    },
    userInformalOffer: {
      index: () => "/api/user_informal_offers",
      total: () => "/api/total/user_informal_offers",
    },
    archive: {
      index: () => "/api/archives",
    },
    adminStaff: {
      index: () => "/api/admin_staffs",
      create: () => "/api/admin_staffs",
      update: () => `/api/admin_staff`,
      destroy: (id: string) => `/api/admin_staffs/${id}`,
    },
  },
  company: {
    company: {
      index: () => "/api/companies",
      show: (id: string) => `/api/companies/${id}`,
      total: () => "/api/total/companies",
    },
    profile: {
      show: (companyId: string) => `/api/companies/${companyId}/profile`,
    },
    detail: {
      show: (companyId: string) => `/api/companies/${companyId}/detail`,
    },
    recruit: {
      show: (companyId: string) => `/api/companies/${companyId}/recruit`,
    },
    attachments: {
      index: (companyId: string) => `/api/companies/${companyId}/attachments`,
    },
    workplaces: {
      index: (companyId: string) => `/api/companies/${companyId}/workplaces`,
    },
    favoriteUser: {
      index: (companyId: string) => `/api/companies/${companyId}/favorite_users`,
    },
    employeeVoices: {
      index: (companyId: string) => `/api/companies/${companyId}/employee_voices`,
    },
  },
  user: {
    user: {
      index: () => "/api/users",
      show: (id: string) => `/api/users/${id}`,
      total: () => "/api/total/users",
    },
    attachments: {
      index: (userId: string) => `/api/users/${userId}/attachments`,
    },
    favoriteCompany: {
      index: (userId: string) => `/api/users/${userId}/favorite_companies`,
    },
    academicHistory: {
      index: (userId: string) => `/api/users/${userId}/academic_histories`,
    },
    Job: {
      index: (userId: string) => `/api/users/${userId}/jobs`,
    },
    Secret: {
      index: (userId: string) => `/api/users/${userId}/secrets`,
    },
    Qualification: {
      index: (userId: string) => `/api/users/${userId}/qualifications`,
    },
    Prefecture: {
      index: (userId: string) => `/api/users/${userId}/prefectures`,
    },
    CareerHistory: {
      index: (userId: string) => `/api/users/${userId}/career_histories`,
    },
    userAssessment: {
      total: () => "/api/total/user_assessments",
    },
  },
  assessment: {
    company: {
      index: () => "api/assessment/company_assessments",
      show: (id: string) => `api/assessment/company_assessments/${id}`,
    },
    employee: {
      index: () => "api/assessment/employee_assessments",
      show: (id: string) => `api/assessment/employee_assessments/${id}`,
    },
    directly: {
      index: () => "api/assessment/directly_assessments",
      show: (id: string) => `api/assessment/directly_assessments/${id}`,
    },
    user: {
      index: () => "api/assessment/user_assessments",
      show: (id: string) => `api/assessment/user_assessments/${id}`,
    },
  },
} as const;

export default endpoint;
