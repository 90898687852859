import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import UserInformalOffer from 'domain/model/user_informal_offer';
import { Sortable, Filterable } from 'domain/repository/params';
import { Count } from 'domain/repository/res';
import Base from 'domain/repository/base';

export default class UserInformalOfferRepository extends Base {
  async fetchIndex({
    page,
    sort,
    filters,
  }: {
    page?: number;
    sort?: Sortable | null;
    filters?: Filterable[];

  }) {
    return this.api.get(endpoint.common.userInformalOffer.index(), {params: {page, ...sort, filters: filters}}).then(res => {
      const response: { userInformalOffers: UserInformalOffer[]; total: number } = {
        userInformalOffers: res.data.informal_offers.map((offer: UserInformalOffer) =>
          plainToClass(UserInformalOffer, offer, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };

  async fetchCount() {
    return this.api.get(endpoint.common.userInformalOffer.total()).then(res => {
      return {
        total: res.data.total,
      } as Count;
    });
  };
}
