import React, { ReactNode, useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useLocation } from "react-router-dom";

import { useAuth } from 'components/lib/AuthProvider';
import { Sidebar, sidebarWidth } from "components/Layout/Sidebar";

type Props = {
  children: ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    grow: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    content: {
      width: `calc(100% - ${sidebarWidth}px)`,
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: 0,
    },
  })
);

const Layout: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [disableLayout, setDisableLayout] = useState<boolean>(false)
  const { pathname } = useLocation();
  const auth = useAuth();

  useEffect(() => {
    const authMatched = !!pathname.match(/^\/auth\//);
    setDisableLayout(authMatched)
  }, [pathname])

  return (
    <>
    {
      disableLayout ? props.children : (
        <div className={classes.root}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" color="inherit">
                CIY管理画面
              </Typography>
              <div className={classes.grow} />
              <Button color="inherit" onClick={() => auth.logout()}>Logout</Button>
            </Toolbar>
          </AppBar>
          <Sidebar />
          <main className={classes.content}>
            <Toolbar />
            {props.children}
          </main>
        </div>
      )
    }
    </>
  )
};

export default Layout;
