import { CompanyDetailBase } from "domain/model/base";
import { Expose } from "class-transformer";

export default class CompanyDetail extends CompanyDetailBase {
  @Expose({name: "str_publish_job_desc"})
  strPublishJobDesc: string;

  @Expose({name: "str_publish_job_procedures"})
  strPublishJobProcedures: string;

  @Expose({name: "str_publish_ideal_figure"})
  strPublishIdealFigure: string;

  @Expose({name: "str_publish_required_ability"})
  strPublishRequiredAbility: string;
};
