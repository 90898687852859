import { Expose } from "class-transformer";
import { MessageBase } from "domain/model/base";

export default class Message extends MessageBase {
  @Expose({name: "user_name"})
  userName?: string;

  @Expose({name: "company_name"})
  companyName?: string;
};
