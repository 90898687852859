import React, { useState, useCallback } from "react";
import clsx from 'clsx';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  IconButton,
  Collapse,
} from '@material-ui/core';
import {
  ExpandMore,
} from '@material-ui/icons';

import Attachment from 'domain/model/attachment';

type Props = {
  attachments: Attachment[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginBottom: theme.spacing(5),
    },
    innerMargin: {
      marginBottom: theme.spacing(2),
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    logo: {
      marginRight: 'auto',
      display: 'block',
      maxWidth: '30%',
    },
    image: {
      marginRight: 'auto',
      display: 'block',
      maxWidth: '100%',
    },
  })
);

export const ImageCard: React.FC<Props> = ({
  attachments,
}) => {
  const classes = useStyles();
  const [opened, setOpened] = useState<boolean>(false);

  const logo = attachments.filter((attachment) => attachment.name == "logo")[0];
  const images = attachments.filter((attachment) => attachment.name.startsWith("image"));

  const switchOpened = useCallback(()=>{
    setOpened(!opened)
  }, [opened]);

  return (
    <Card className={classes.margin}>
      <CardHeader
        title="画像一覧"
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: opened,
            })}
            onClick={switchOpened}
            aria-expanded={opened}
            aria-label="show more"
          >
            <ExpandMore />
          </IconButton>
        }
      />
      <CardContent>
        <Collapse in={opened} timeout="auto" unmountOnExit>
          <Typography className={classes.innerMargin}>ロゴ</Typography>
          <div className={classes.innerMargin}>
            {
              logo ? (<img className={classes.logo} src={logo.url} />)
                : (<Typography>データが存在しません</Typography>)
            }
          </div>
          <Typography className={classes.innerMargin}>企業を表す写真</Typography>
          {
            images.length != 0 ? (
              <Grid container spacing={3} justify="flex-start" className={classes.innerMargin}>
                { images.map(attachment => (
                  <Grid item xs={12} sm={2} key={attachment.id}>
                    <img className={classes.image} src={attachment.url} />
                  </Grid>
                ))}
              </Grid>
              ) : (
                <Typography>データが存在しません</Typography>
              )
          }
        </Collapse>
      </CardContent>
    </Card>
  )
};
