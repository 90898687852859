import React, { useState } from "react";
import {
  FormControl,
  TextField,
  MenuItem,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Modal,
} from '@material-ui/core';
import {
  Clear,
} from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';

import {
  FieldDefinition,
} from 'domain/model/field_difinition';
import { useFilter } from "hooks/useFilter";
import { Filterable, OperatorType } from 'domain/repository/params';
import { OperatorTypes } from 'utils/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginBottom: 20,
    },
    formControl: {
      marginLeft: theme.spacing(3),
      width: '100%',
    },
    buttonWrap: {
      margin: 15,
      marginTop: 30,
      paddingRight: 20,
      textAlign: 'right',
    },
    modalBody: {
      width: '60%',
      position: 'absolute',
      margin: 'auto',
      left:  0,
      right: 0,
      top: '30%',
    },
    buttonBlock: {
      paddingBottom: 20,
      textAlign: 'right',
    }
  }),
);

export const FilterForm: React.FC<{
  handleFilter: (filters: Filterable[]) => void,
  fieldDefinition: FieldDefinition[],
}> = ({
  handleFilter,
  fieldDefinition,
}) => {
  const { filters, appendFilter, setFilters } = useFilter();
  const classes = useStyles();
  const [opened, setOpened] = useState<boolean>(false);

  const handleAppendFilter = (filter: Filterable) => {
    appendFilter(filter)
  }

  const handleRemoveFilter = (index: number) => {
    const newFilters = [...filters]
    newFilters.splice(index, 1)
    setFilters(newFilters);
  }

  const submit = () => {
    handleFilter(filters)
    setOpened(false);
  }

  const handleClose = () =>   {
    setOpened(false);
  }

  return (
    <>
    <div className={classes.buttonBlock}>
      <Button
        onClick={() => { setOpened(true) }}
        variant="contained"
        color="primary"
      >
        絞り込み
      </Button>
    </div>
    <Modal
      open={opened}
      onClose={handleClose}
    >
      <Card variant="outlined" className={classes.modalBody}>
        <CardHeader
          action={
            <IconButton
              onClick={() => handleClose() }
              aria-expanded={opened}
              aria-label="show more"
            >
              <Clear />
            </IconButton>
          }
        />
        <CardContent>
          {
            filters.map((filter, index)=> (
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      select
                      placeholder="field"
                      value={filter.field}
                      disabled={true}
                    >
                      <MenuItem value={filter.field}>{filter.field}</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      select
                      placeholder="field"
                      value={filter.operator}
                      disabled={true}
                    >
                      <MenuItem value={filter.operator}>{OperatorType[filter.operator]}</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      placeholder="value"
                      value={filter.value}
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={3} style={{textAlign: "center"}}>
                  <FormControl>
                    <Button onClick={() => handleRemoveFilter(index)} variant='contained' color="secondary">削除</Button>
                  </FormControl>
                </Grid>
              </Grid>
            ))
          }

          <FilterField
            handleAppendFilter={handleAppendFilter}
            fieldDefinition={fieldDefinition}
          />
          <div className={classes.buttonWrap}>
            <Button onClick={submit} color="primary" variant="contained" disabled={filters.length === 0}>絞り込み</Button>
          </div>
        </CardContent>
      </Card>
    </Modal>
    </>
  )
};

const valueTypeMap: {[key: string]: string} = {
  'number': 'number',
  'string': 'text',
  'date': 'date',
  '': 'text',
}

const FilterField: React.FC<{
  handleAppendFilter: (filter: Filterable) => void,
  fieldDefinition: FieldDefinition[],
}> = ({
  handleAppendFilter,
  fieldDefinition,
}) => {
  const classes = useStyles();
  const { control, register, handleSubmit, errors, reset, formState, watch } = useForm<Filterable>();
  const { field } = watch();

  const fieldDef = fieldDefinition.find(el => el.field == field);

  const handleOnSubmit = (form: Filterable) => {
    handleAppendFilter(form);
    reset();
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Grid container spacing={3}>
        <Grid item md={3}>
          <FormControl className={classes.formControl}>
            <Controller
              control={control}
              name="field"
              rules={{required: "選択してください"}}
              render={({ onChange, value }) => (
                <TextField
                  select
                  placeholder="field"
                  onChange={value => onChange(value)}
                  error={Boolean(errors.field)}
                  helperText={errors.field?.message}
                  name="field"
                  value={value}
                >
                  {
                    fieldDefinition.map((fd) => (
                      <MenuItem value={fd.field} key={fd.field}>{fd.fieldCamelCase}</MenuItem>
                    ))
                  }
                </TextField>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl className={classes.formControl}>
            <Controller
              control={control}
              name="operator"
              rules={{required: "選択してください"}}
              render={({ onChange, value }) => (
                <TextField
                  select
                  placeholder="operator"
                  name="operator"
                  error={Boolean(errors.operator)}
                  helperText={errors.operator?.message}
                  onChange={value => onChange(value)}
                  value={value}
                >
                  {
                    (Object.keys(OperatorType) as (OperatorTypes)[]).map((key) => {
                      return (
                        <MenuItem value={key} key={key}>{OperatorType[key]}</MenuItem>
                      )
                    })
                  }
                </TextField>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl className={classes.formControl}>
            {
              fieldDef?.type != "boolean" ? (
                <TextField
                  placeholder="value"
                  type={valueTypeMap[fieldDef?.type || '']}
                  name="value"
                  error={Boolean(errors.value)}
                  helperText={errors.value?.message}
                  inputRef={
                    register({
                      required: "選択してください",
                    })
                  }
                />
                ) : (
                  <Controller
                    control={control}
                    name="value"
                    rules={{required: "選択してください"}}
                    render={({ onChange, value }) => (
                      <TextField
                        select
                        placeholder="value"
                        onChange={value => onChange(value)}
                        error={Boolean(errors.field)}
                        helperText={errors.field?.message}
                        name="value"
                        value={value}
                      >
                        <MenuItem value="true">true</MenuItem>
                        <MenuItem value="false">false</MenuItem>
                      </TextField>
                    )}
                  />
                )
            }
          </FormControl>
        </Grid>
        <Grid item md={3} style={{textAlign: "center"}}>
          <FormControl>
            <Button type="submit" color="primary" variant="contained">
              追加
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  )
}
