import React, { useState, useEffect } from "react";
import { RowsProp, DataGrid, CellParams } from '@material-ui/data-grid';
import { Toolbar } from '@material-ui/core';
import { useHistory, generatePath } from "react-router-dom";

import { EmployeeAssessmentRepository } from 'domain/repository';
import routes from 'utils/routes';
import { useRepository } from 'components/lib/Repository';
import { CustomPagination } from 'components/Pagination';
import { useFilter } from "hooks/useFilter";
import { FilterForm } from 'components/Filter';
import { EmployeeAssessmentFieldDefinition } from 'domain/model/field_difinition';
import { useIndex } from 'hooks/useIndex';

const ignoreFields = ["password_digest", "company_name", "birth_time_type", "answer", "result"]
const employeeAssessmentFieldDefinition = EmployeeAssessmentFieldDefinition.filter(v => !ignoreFields.includes(v.field) )

export const EmployeeAssessmentIndex = () => {
  const history = useHistory();
  const employeeAssessmentRepository = useRepository(EmployeeAssessmentRepository);
  const [rows, setRows] = useState<RowsProp>([]);
  const {
    page,
    totalPage,
    sort,
    sortingMode,
    handlePageChange,
    handleSortChange,
    changeTotal,
  } = useIndex();
  const { filters, handleSetFilters, } = useFilter();

  const handleClickCell = (param: CellParams): void => {
    const { field, value } = param;
    if (field === 'id') {
      const path = generatePath(routes.company.employeeAssessmentShow.path, {id: value as number});
      history.push(path);
    }
  }

  useEffect(() => {
    employeeAssessmentRepository.fetchIndex({page, sort, filters: filters}).then(res => {
      const { total } = res;
      changeTotal(total);
      setRows(res.assessments);
    });
  }, [page, sort, filters]);

   return (
    <>
      <Toolbar>従業員アセスメント一覧</Toolbar>
      <FilterForm
        handleFilter={handleSetFilters}
        fieldDefinition={employeeAssessmentFieldDefinition}
      />
      <div style={{ width: '100%', minHeight: 600 }}>
      <DataGrid
          rows={rows}
          columns={[
            {
              field: 'id',
            },
            {
              field: 'companyId',
              headerName:"企業ID",
              width: 150,
              sortable: false,
            },
            {
              field: 'companyName',
              headerName:"企業名",
              width: 250,
              sortable: false,
            },
            {
              field: 'email',
              headerName:"メールアドレス",
              width: 300,
            },
            {
              field: 'name',
              headerName:"診断者名",
              width: 250,
              sortable: false,
            },
            {
              field: 'department',
              headerName:"部署",
              width: 200,
              sortable: false,
            },
            {
              field: 'status',
              headerName:"ステータス",
              sortable: false,
              width: 150,
            },
            {
              field: 'createdAt',
              headerName:"登録日",
              sortable: false,
              width: 250,
            },
            {
              field: 'updatedAt',
              headerName:"更新日",
              sortable: false,
              width: 250,
            },
          ]}
          pagination
          rowCount={totalPage}
          paginationMode="server"
          sortingMode={sortingMode}
          onCellClick={handleClickCell}
          onPageChange={handlePageChange}
          onSortModelChange={handleSortChange}
          components={{pagination: CustomPagination}}
          rowsPerPageOptions={[100]}
          disableColumnResize={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          loading={false}
        />
      </div>
    </>
  );
};
