import React, { useEffect, useState } from "react";
import {
  Toolbar,
} from '@material-ui/core';
import { useParams } from "react-router-dom";

import { UserAssessmentRepository } from 'domain/repository';
import UserAssessment from 'domain/model/user_assessment';
import { CardTable } from 'components/Table/CardTable';
import {
  UserAssessmentFieldDefinition,
} from 'domain/model/field_difinition'
import { useRepository } from 'components/lib/Repository';

type IdParams = {
  id: string;
};

export const UserAssessmentShow = () => {
  const { id } = useParams<IdParams>();
  const [userAssessment, setUserAssessment] = useState<UserAssessment | null>(null);
  const userAssessmentRepository = useRepository(UserAssessmentRepository);

  useEffect(() => {
    userAssessmentRepository.fetchShow({id}).then(res => {
      setUserAssessment(res);
    })
  }, [id])

   return (
    <>
      <Toolbar>アセスメント詳細</Toolbar>
      <CardTable
        title="求職者アセスメント"
        model={userAssessment}
        fieldDefinition={UserAssessmentFieldDefinition}
      />
    </>
  );
};
