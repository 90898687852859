import React, { useState } from "react";
import { PageChangeParams, SortModelParams } from '@material-ui/data-grid';
import { Sortable } from 'domain/repository/params';

export const useIndex = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [sort, setSort] = useState<Sortable | null>();
  const [sortingMode, setSortingMode] = useState<"client" | "server">("server");

  const handlePageChange = (params: PageChangeParams) => {
    setPage(params.page);
  };

  const handleSortChange = (params: SortModelParams) => {
    setSort(params.sortModel[0]);
  }

  const changeTotal = (param: number) => {
    param < 100 ? setSortingMode("client") : setSortingMode("server")
    setTotalPage(param);
  }

  const pageReload = () => {
    return window.location.reload();
  }

  return {
    page,
    totalPage,
    sort,
    sortingMode,
    handlePageChange,
    handleSortChange,
    changeTotal,
    pageReload,
  }
}
