import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { 
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  TextField,
  Button,
} from '@material-ui/core';
import  {
  AccountCircle,
} from '@material-ui/icons';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useForm } from "react-hook-form";

import routes from 'utils/routes';
import { AdminStaffRepository } from 'domain/repository';
import { useRepository } from 'components/lib/Repository';
import AdminStaff from 'domain/model/admin_staff';
import { useAuth } from 'components/lib/AuthProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      margin: theme.spacing(2),
      width: "25vw",
    },
    grid: {
      height: "70vh",
    },
  }),
);

type RegistrationFormBody = {
  name: string;
};

export const AdminStaffEdit = () => {
  const classes = useStyles();
  const history = useHistory();
  const adminStaffRepository = useRepository(AdminStaffRepository);
  const { staff, setStaff } = useAuth();
  if (staff === null) {return<></>}
  
  const update =(form: RegistrationFormBody) => {
    return adminStaffRepository.update(form.name)
    .then(res =>{
      setStaff(res)
      history.push(routes.common.adminStaffIndex.path)
    })
  }
  
  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.grid}
      >
        <Card>
          <CardHeader
            title="管理者情報変更"
          />
          <CardContent>
            <RegistrationForm update={update} adminStaff={staff} />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

type Props = {
  update: (form: RegistrationFormBody) => void;
  adminStaff: AdminStaff;
};

export const RegistrationForm: React.FC<Props> = ({ update, adminStaff }) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm<RegistrationFormBody>({defaultValues: {name: adminStaff.name}});

  return (
    <>
      <form onSubmit={handleSubmit(update)}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <TextField
            type="name"
            name="name"
            label="変更後ログインID"
            className={classes.form}
            inputRef={register({ required: "入力してください"})}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary" className={classes.form} type="submit">変更</Button>
        </Grid>
      </form>
    </>
  )
}
