import React, { useEffect, useState } from "react";
import {
  Toolbar,
} from '@material-ui/core';
import { useParams } from "react-router-dom";

import { CompanyAssessmentRepository } from 'domain/repository';
import CompanyAssessment from 'domain/model/company_assessment';
import { useLoading } from 'components/lib/LoadingProvider';
import { CardTable } from 'components/Table/CardTable';
import {
  CompanyAssessmentFieldDefinition,
} from 'domain/model/field_difinition'
import { useRepository } from 'components/lib/Repository';

type IdParams = {
  id: string;
};

export const CompanyAssessmentShow = () => {
  const { id } = useParams<IdParams>();
  const [companyAssessment, setCompanyAssessment] = useState<CompanyAssessment | null>(null);
  const companyAssessmentRepository = useRepository(CompanyAssessmentRepository);

  useEffect(() => {
    companyAssessmentRepository.fetchShow({id}).then(res => {
      setCompanyAssessment(res);
    })
  }, [id])

   return (
    <>
      <Toolbar>企業アセスメント詳細</Toolbar>
      <CardTable
        title="企業向けアセスメント"
        model={companyAssessment}
        fieldDefinition={CompanyAssessmentFieldDefinition}
      />
    </>
  );
};
