import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import CompanyProfile from 'domain/model/company_profile';
import Base from 'domain/repository/base';

export default class CompanyProfileRepository extends Base {
  async fetchShow({
    companyId,
  }: {
    companyId: string;
  }) {
    return this.api.get(endpoint.company.profile.show(companyId)).then(res => {
      return plainToClass(CompanyProfile, res.data.profile, { excludeExtraneousValues: true })
    });
  };
}
