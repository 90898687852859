import React, { useState } from "react";
import { Filterable } from 'domain/repository/params';

export const useFilter = () => {
  const [filters, setFilters] = useState<Filterable[]>([]);

  const appendFilter = (filter: Filterable) => {
    setFilters([...filters, filter])
  }

  const handleSetFilters = (params: Filterable[]) => {
    setFilters(params);
  }

  return {
    filters,
    appendFilter,
    setFilters,
    handleSetFilters,
  };
};
