import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import UserCareerHistory from 'domain/model/user_career_history';
import Base from 'domain/repository/base';

export default class UserCareerHistoryRepository extends Base {
  async fetchIndex({
    userId,
    page,
  }: {
    userId: string;
    page?: number;
  }) {
    return this.api.get(endpoint.user.CareerHistory.index(userId), {params: {page}}).then(res => {
      const response: { careerHistories: UserCareerHistory[]; total: number } = {
        careerHistories: res.data.career_histories.map((career_history: UserCareerHistory) =>
          plainToClass(UserCareerHistory, career_history, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };
}
