import axios, { AxiosInstance } from 'axios';

import { getToken } from 'utils/storage';
import { getBaseURL } from 'utils/api'
import { useLoading } from 'components/lib/LoadingProvider';
import { useError, hasNotifyError } from "hooks/useError";

export const useRepository = <T extends {}>(repo: { new(_: AxiosInstance): T }): T => {
  const { startLoading, endLoading } = useLoading();
  const { enqueueError } = useError();

  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Authorization: `Bearer ${getToken()}`,
  };

  const api = axios.create({
    baseURL: getBaseURL(),
    headers,
    responseType: "json",
  });

  api.interceptors.request.use(req => {
    startLoading()
    return req;
  });

  api.interceptors.response.use(
    (res) => {
      endLoading();
      return res;
    },
    (err) => {
      const { data } = err.response;
      if (hasNotifyError(data)) {
        enqueueError(data.notify_error)
      }
      // 共通処理はここに記載
      endLoading();
      return Promise.reject(err);
    }
  );

  return new repo(api);
}
