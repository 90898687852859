import React, { useState, useEffect } from "react";
import { RowsProp, DataGrid, ValueGetterParams, CellParams } from '@material-ui/data-grid';
import { 
  Toolbar, 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { AdminStaffRepository } from 'domain/repository';
import { useRepository } from 'components/lib/Repository';
import { CustomPagination } from 'components/Pagination';
import { useFilter } from "hooks/useFilter";
import { FilterForm } from 'components/Filter';
import { AdminStaffFieldDefinition } from 'domain/model/field_difinition';
import { useIndex } from 'hooks/useIndex';

const ignoreFields = ["password_digest", "role"]
const adminStaffFieldDefinition = AdminStaffFieldDefinition.filter(v => !ignoreFields.includes(v.field) )

export const AdminStaffIndex = () => {
  const adminStaffRepository = useRepository(AdminStaffRepository);
  const [rows, setRows] = useState<RowsProp>([]);
  const {
    page,
    totalPage,
    sort,
    sortingMode,
    handlePageChange,
    handleSortChange,
    changeTotal,
  } = useIndex();
  const { filters, handleSetFilters, } = useFilter();
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState<string>("");

  const handleClickOpenDialog = (id:string) => {
    setOpen(true);
    setId(id)
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const getId = (params: ValueGetterParams) => {
    return params.getValue('id')
  }

  const destroy = () => {
    adminStaffRepository.destroy(id)
    .then(()=>{
      adminStaffRepository.fetchIndex({page, sort, filters: filters}).then(res => {
        const { total } = res;
        changeTotal(total);
        setRows(res.admin_staffs);
      })
      setOpen(false)
    })
  }

  useEffect(() => {
    adminStaffRepository.fetchIndex({page, sort, filters: filters}).then(res => {
      const { total } = res;
      changeTotal(total);
      setRows(res.admin_staffs);
    });
  }, [page, sort, filters]);

   return (
    <>
      <Toolbar>管理者一覧</Toolbar>
      <FilterForm
        handleFilter={handleSetFilters}
        fieldDefinition={adminStaffFieldDefinition}
      />
      <div style={{ width: '100%', minHeight: 600 }}>
        <DataGrid
          rows={rows}
          columns={[
            {
              field: 'id',
            },
            {
              field: 'name',
              width: 250,
              sortable: false,
            },
            {
              field: 'createdAt',
              width: 250,
              sortable: false,
            },
            {
              field: 'updatedAt',
              width: 250,
              sortable: false,
            },
            {
              field: 'delete',
              width: 100,
              sortable: false,
              valueGetter: getId,
              renderCell: (params: CellParams) => (
                <>
                  <IconButton aria-label="delete" onClick={()=>handleClickOpenDialog(params.value as string)}>
                    <Delete />
                  </IconButton>
                  削除
                </>
              ),
            },
          ]}
          pagination
          rowCount={totalPage}
          paginationMode="server"
          sortingMode={sortingMode}
          onPageChange={handlePageChange}
          onSortModelChange={handleSortChange}
          components={{pagination: CustomPagination}}
          rowsPerPageOptions={[100]}
          disableColumnResize={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          loading={false}
        />
      </div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            いいえ
          </Button>
          <Button onClick={destroy} color="primary" autoFocus>
            はい
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};