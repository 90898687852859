import {
  Dashboard,
  Business,
  Person,
  List,
  Message,
  Create,
  SvgIconComponent,
  ArrowDropDownCircle,
  SupervisorAccount,
  Edit,
} from "@material-ui/icons";
import routes from 'utils/routes';

export type SidebarConfig = {
  icon: SvgIconComponent;
  title: string;
  path?: string;
  subMenu?: {
    icon: SvgIconComponent;
    title: string;
    path: string;
  }[];
};

export const sidebarMenus = (): SidebarConfig[] => {
  return [
    {
      icon: Dashboard,
      title: "ダッシュボード",
      path: routes.common.dashboard.path,
    },
    {
      icon: SupervisorAccount,
      title: "管理者",
      subMenu: [
        {
          icon: List,
          title: "管理者一覧",
          path: routes.common.adminStaffIndex.path,
        },
        {
          icon: Create,
          title: "管理者作成",
          path: routes.common.adminStaffCreate.path,
        },
        {
          icon: Edit,
          title: "管理者編集",
          path: routes.common.adminStaffEdit.path,
        },
      ],
    },
    {
      icon: Business,
      title: "企業",
      subMenu: [
        {
          icon: List,
          title: "企業一覧",
          path: routes.company.companyIndex.path,
        },
        {
          icon: List,
          title: "企業アセスメント一覧",
          path: routes.company.companyAssessmentIndex.path,
        },
        {
          icon: List,
          title: "従業員アセスメント一覧",
          path: routes.company.employeeAssessmentIndex.path,
        },
        {
          icon: List,
          title: "候補者アセスメント一覧",
          path: routes.company.directlyAssessmentIndex.path,
        },
        {
          icon: List,
          title: "内定報告一覧",
          path: routes.company.informalOfferIndex.path,
        },
      ],
    },
    {
      icon: Person,
      title: "ユーザー",
      subMenu: [
        {
          icon: List,
          title: "一覧",
          path: routes.user.userIndex.path,
        },
        {
          icon: List,
          title: "アセスメント一覧",
          path: routes.user.userAssessmentIndex.path,
        },
        {
          icon: List,
          title: "内定報告一覧",
          path: routes.user.userInformalOfferIndex.path,
        },
      ],
    },
    {
      icon: Message,
      title: "メッセージ",
      subMenu: [
        {
          icon: List,
          title: "公式メッセージ一覧",
          path: routes.common.officialMessageIndex.path,
        },
        {
          icon: List,
          title: "メッセージ一覧",
          path: routes.common.messageIndex.path,
        },
      ],
    },
    {
      icon: ArrowDropDownCircle,
      title: "CSVダウンロード",
      path: routes.common.csv.path,
    },
  ];
};
