import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemSecondaryAction,
} from '@material-ui/core';
import {
  ExpandMore,
} from '@material-ui/icons';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';

import { FieldDefinition } from 'domain/model/field_difinition'

type Props = {
  title: string,
  model: any | null;
  fieldDefinition: FieldDefinition[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginBottom: theme.spacing(5),
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  })
);

export const CardTable: React.FC<Props> = ({
  title,
  model,
  fieldDefinition,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <Card className={classes.margin}>
      <CardHeader
        title={title}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: opened,
            })}
            onClick={() => { setOpened(!opened) }}
            aria-expanded={opened}
            aria-label="show more"
          >
            <ExpandMore />
          </IconButton>
        }
      />
      <CardContent>
        <Collapse in={opened} timeout="auto" unmountOnExit>
          {
            model !== null ?
              fieldDefinition.map((fd) => (
                <Grid container spacing={3} key={fd.field}>
                  <Grid item xs={12} sm={3}>
                    <Typography color="textSecondary" gutterBottom>
                      {fd.displayName}
                      {fd.displayName==null && fd.field}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {
                      fd.type === "object" ?
                        <ObjectContent model={model[fd.fieldCamelCase]} />
                      : (
                        <Typography gutterBottom style={{ wordWrap: "break-word", width: "100%" }} >
                          {(model)[fd.fieldCamelCase]}
                        </Typography>
                      )
                    }
                  </Grid>
                </Grid>
              )) : (
                <Typography>データが存在しません</Typography>
              )
          }
        </Collapse>
      </CardContent>
    </Card>
  )
};

const ObjectContent: React.FC<{
  model: any
}> = ({model}) => {
  const classes = useStyles();
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <>
      {
        model ? (
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: opened,
                  })}
                  onClick={() => { setOpened(!opened) }}
                  aria-expanded={opened}
                  aria-label="show more"
                >
                  <ExpandMore />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
              <Collapse in={opened} timeout="auto" unmountOnExit>
                {
                  Object.keys(model).map(key => {
                    return (
                      <ListItem key={key}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={3}>
                            <Typography color="textSecondary" gutterBottom>
                              {key}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography align="right" gutterBottom style={{ wordWrap: "break-word", width: "100%" }} >
                              {model[key]}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )
                  })
                }
            </Collapse>
          </List>
        ) : (
          <Typography>データが存在しません</Typography>
        )
      }
    </>
  );
};
