import { plainToClass } from "class-transformer";

import endpoint from 'utils/endpoint';
import UserQualification from 'domain/model/user_qualification';
import Base from 'domain/repository/base';

export default class UserQualificationRepository extends Base {
  async fetchIndex({
    userId,
    page,
  }: {
    userId: string;
    page?: number;
  }) {
    return this.api.get(endpoint.user.Qualification.index(userId), {params: {page}}).then(res => {
      const response: { qualifications: UserQualification[]; total: number } = {
        qualifications: res.data.qualifications.map((qualification: UserQualification) =>
          plainToClass(UserQualification, qualification, { excludeExtraneousValues: true })
        ),
        total: res.data.total_pages,
      };
      return response;
    });
  };
}
