import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import Layout from 'components/Layout';
import { AuthProvider } from 'components/lib/AuthProvider';
import { LoadingProvider } from 'components/lib/LoadingProvider';
import { Loading } from 'components/Loading';
import routes from 'utils/routes';

function App() {
  return (
    <Router>
      <CssBaseline />
      <LoadingProvider>
        <SnackbarProvider>
          <Loading />
          <AuthProvider>
            <Switch>
              <Layout>
                {
                  Object.values(routes.company).map(({ path, component }, index) =>
                    <Route exact path={path} component={component} key={index} />
                  )
                }
                {
                  Object.values(routes.common).map(({ path, component }, index) =>
                    <Route exact path={path} component={component} key={index} />
                  )
                }
                {
                  Object.values(routes.user).map(({ path, component }, index) =>
                    <Route exact path={path} component={component} key={index} />
                  )
                }
              </Layout>
            </Switch>
          </AuthProvider>
        </SnackbarProvider>
      </LoadingProvider>
    </Router>
  );
}

export default App;
